import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Import useNavigate
import "./Requestvincity.css";
import MapTest from "./MapTest";

const RequestVincity = () => {
  const [popup, setPopup] = useState(true);
  const [requestName, setRequestName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const openPopup = () => setPopup(true);
  const closePopup = () => setPopup(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    const formData = {
      requestName,
      description,
      category,
    };

    // Navigate to /maptest and pass form data
    navigate("/maptest", { state: formData });
  };

  return (
    <>
      <div>
        <br />
        <br />
        <br />
        <br />
        <button className="reqvin-btn" onClick={openPopup}>
          Request in Vincity
        </button>
        {/* <MapTest /> */}
      </div>

      {popup && (
        <div className="rv-popup-overlay" onClick={closePopup}>
          <div
            className="rv-popup-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="rv-close-btn" onClick={closePopup}>
              ×
            </button>
            <h2 className="rv-popup-title">Request Details</h2>
            <p className="rv-popup-message">Fill out the request form below:</p>

            {/* Form inside the popup */}
            <form className="rv-popup-form" onSubmit={handleSubmit}>
              <label>
                Request Name:
                <input
                  type="text"
                  value={requestName}
                  onChange={(e) => setRequestName(e.target.value)}
                  required
                />
              </label>
              <label>
                Description:
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </label>
              <label>
                Category:
                <input
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                />
              </label>
              <button type="submit" className="rv-submit-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestVincity;
