// SaleIcon.js
const SaleIcon = (props) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* Red Box */}
      <rect
        x="10"
        y="30"
        width="100"
        height="40"
        fill="red"
        style={{ rx: 5 }} // Optional: round the corners
      />

      {/* Triangle */}
      {/* <polygon points="90,50 100,70 90,70" fill="red" /> */}

      {/* Circle in the middle of the Triangle */}
      {/* <circle cx="95" cy="60" r="3" fill="white" /> */}

      {/* Sale Text */}
      <text
        x="20"
        y="55"
        fill="white"
        fontSize="20"
        fontWeight="bold"
        style={{ fontFamily: "Arial, sans-serif" }} // Optional: specify font family
      >
        For Sale
      </text>
    </svg>
  );
};

export default SaleIcon;
