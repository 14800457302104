import React, { useState, useEffect } from "react";
import "./Inbox.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Inbox() {
  const [isOpen, setIsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [messages, setMessages] = useState([]);
  const userName = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchParticipation = async () => {
      const name = localStorage.getItem("name");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/academic-request/participation`,
          {
            params: {
              email: email,
              name: name,
            },
          }
        );

        // Check if response data is defined and contains the expected structure
        if (response.data) {
          console.log("API Response:", response.data);

          const acceptedRequests = response.data.acceptedRequests || [];
          const appliedRequests = response.data.appliedRequests || [];
          const sentRequests = response.data.sentRequests || [];

          // Extract room names from the response
          const roomNames = [
            ...acceptedRequests.map(
              (req) => req.roomname || req.roomName || ""
            ), // Assuming `roomname` is elsewhere, else can be left out
            ...appliedRequests.map((req) => req.code || ""), // Use the code from appliedRequests
            ...sentRequests.flatMap(
              (req) =>
                Object.values(req.applicantsWithCodes).map(
                  (applicant) => applicant.code
                ) // Flatten the codes from applicants
            ),
          ].filter((name) => name); // Filter out any empty names

          console.log(roomNames); // Check the extracted roomNames

          fetchUnreadMessages(roomNames);
        } else {
          console.error("No data found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching participation data:", error);
      }
    };

    fetchParticipation();
  }, [email]);

  const fetchUnreadMessages = async (roomNames) => {
    if (roomNames.length === 0) return; // Exit if no room names

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/messages/unread`, // Adjust this endpoint as necessary
        {
          params: {
            roomNames: roomNames,
            userName: userName, // Exclude messages sent by this user
          },
        }
      );

      setMessages(response.data || []);
      setUnreadCount(response.data.filter((msg) => msg.unread).length); // Count unread messages
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    }
  };
  // const fetchAllMessages = async (roomNames) => {
  //   if (roomNames.length === 0) return; // Exit if no room names

  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_SERVER_URL}/api/messages/all`, // Adjusted to fetch all messages
  //       {
  //         params: {
  //           roomNames: roomNames,
  //           userName: userName, // Exclude messages sent by this user
  //         },
  //       }
  //     );
  //     console.log(response.data);

  //     setMessages(response.data || []);
  //     setUnreadCount(response.data.filter((msg) => msg.unread).length); // Count unread messages
  //   } catch (error) {
  //     console.error("Error fetching all messages:", error);
  //   }
  // };

  const toggleInbox = () => {
    setIsOpen(!isOpen);
  };

  const markAsRead = async (id) => {
    // Implement your mark as read functionality here
  };

  const formatTime = (time) => {
    const date = new Date(time);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleMessageClick = (message) => {
    localStorage.setItem("generatedCode", message.RequestID);
    // console.log(message);
    navigate(`/chatroom/`); // Navigate to /chatroom with the generated code
  };

  return (
    <div className="inbx-container">
      <button className="inbx-button" onClick={toggleInbox}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
          <polyline points="22,6 12,13 2,6"></polyline>
        </svg>
        {unreadCount > 0 && (
          <span className="inbx-unread-count">{unreadCount}</span>
        )}
      </button>

      {isOpen && (
        <div className="inbx-window">
          <div className="inbx-header">
            <h2>Inbox</h2>
            <button className="inbx-close" onClick={toggleInbox}>
              &times;
            </button>
          </div>
          <div className="inbx-content">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`inbx-message ${
                  message.unread ? "inbx-unread" : ""
                }`}
                onClick={() => handleMessageClick(message)}
              >
                <div className="inbx-message-header">
                  <strong>{message.username}</strong>
                  <span>{formatTime(message.time)}</span>
                </div>
                <p>{message.text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
