import AnimatedButtons from "../Buttons/Animations";
import Chatbutton from "../Buttons/Chatbutton";
import Navbar1 from "../Navbar";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  CardFooter,
} from "../styles";
import { blogarray } from "./blogarray";
import blog4img from "./images/blog4.webp";
import blog2img from "./images/blog21.webp";
import blog3img from "./images/blog31.jpg";
import dtufoto from "./images/dtu.webp";
import people from "./images/people.webp";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import MinimalistMobileMenu from "../MinimalistMobileMenu";

const Blog3 = () => {
  const navigate = useNavigate();
  const topRef = useRef(null);
  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`); // Example URL structure, adjust as per your routing setup
  };
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div ref={topRef} style={{ height: "0", margin: "0", padding: "0" }}>
        <GlobalStyle />
        <Navbar1 />
        <Container>
          <Header>
            <HeaderImage src={blog3img} alt="Header Image" />
            <Title>
              Navigating the Hostel and PG Scene at DTU: A Freshers' Guide
            </Title>
            <Author>by Requesto</Author>
            <Date>July 18, 2024</Date>
          </Header>
          <Separator role="separator">
            <Dot />
            <Dot />
            <Dot />
          </Separator>

          <Content>
            <Paragraph>
              Welcome to Delhi Technological University (DTU)! As you embark on
              this exciting journey, one of the first things you'll need to
              figure out is your accommodation. Whether you're from Delhi or
              outside, understanding the hostel and PG (Paying Guest) options
              available to you is crucial. Let's dive into the details!
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              For Delhi Students About 85% of you are from Delhi and may find
              yourselves commuting from home. However, many Delhi students also
              opt for PG accommodations due to their proximity to the college
              and the convenience they offer. Here's what you need to know:
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Proximity and Cost: Numerous PGs are within walking distance of
              DTU, with prices ranging from ₹6,000 to ₹15,000 per month. The
              costs vary based on the amenities and the type of room (single,
              double, triple, etc.). Popular Choices: Some well-known PGs
              include Yourspace, Leelawati PG, and Sandeep PG. Each offers
              different facilities, so choose based on your preferences and
              budget. Food Options: Most PG residents prefer eating at popular
              spots like SP (Sutta Point) or SFC (Student Food Court). SP is a
              student hub for homemade meals and snacks, while SFC offers
              hygienic and unlimited food, albeit at a higher price. For
              Outstation Students If you've made it through the competitive
              outside-Delhi quota, you'll be allocated one of the first-year
              hostels: Aryabhatta Hostel or Type 2 Hostel.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Vacancies: Initially, rooms are fully allotted, but vacancies
              might arise as some students leave. If you hold a Delhi quota and
              live more than 80 km away, you have a chance of getting a hostel
              room later on. Mess and Facilities: Both Aryabhatta and Type 2
              hostels share a common mess and are conveniently located at the
              entrance of the college, allowing easy access to and from campus.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Life After the First Year Senior Hostels: After your first year,
              you can move into senior hostels like APJ Hostel, which
              accommodates three students per room and features a centralized AC
              system to combat Delhi’s sweltering summers. PG Life vs. Hostel
              Life If you aren't allotted a hostel, don't worry! There are ample
              PG options:
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Freedom and Restrictions: PGs offer various room types and
              generally have later entry times, allowing you to enjoy college
              nightlife. However, note that PG residents cannot roam the campus
              after 11 p.m., a privilege reserved for hostel students.
              Alternative Options: If PGs aren't your style, consider renting a
              flat with friends. There are several flats within a 3 km radius of
              DTU.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Final Thoughts Choosing between hostel and PG life depends on your
              preferences. Hostel life at DTU is known for creating lifelong
              memories and friendships, while PGs offer flexibility and comfort.
              Regardless of your choice, make sure to immerse yourself in the
              vibrant college life at DTU and make the most of your time here.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Welcome to DTU, and here's to an unforgettable college experience!
            </Paragraph>
          </Content>
          <br />
          <br />
          <br />
          <br />
          <ButtonContainer>
            <NavButton onClick={() => navigate("/blog2")}>Previous</NavButton>
            <NavButton onClick={() => navigate("/blog4")}>Next</NavButton>
          </ButtonContainer>
          {/* {blogarray.map((blog) => (
            <BlogCard key={blog.id}>
              <CardImage src={blog.image} alt="Blog Preview" />
              <CardContent>
                <CardTitle>{blog.title}</CardTitle>
                <CardAuthor>by {blog.author}</CardAuthor>
                <CardDate>{blog.date}</CardDate>
                <CardDescription>{blog.description}</CardDescription>
              </CardContent>
              <CardFooter>
                <ReadMoreButton onClick={() => handleReadMore(blog.id)}>
                  Read More
                </ReadMoreButton>
              </CardFooter>
            </BlogCard>
          ))} */}
        </Container>
        <MinimalistMobileMenu/>
        <Footer>&copy; 2024 Requesto. All rights reserved.</Footer>
      </div>
    </>
  );
};

export default Blog3;
