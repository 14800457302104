import React, { useState, useEffect } from 'react';
import './RequestList.css';

const RequestList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/map/requests');
        const data = await response.json();
        setRequests(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching requests:', error);
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  if (loading) {
    return <div className="rl-request-loading">Loading requests...</div>;
  }

  return (
    <div className="rl-request-list">
      {requests.length > 0 ? (
        requests.map((request, index) => (
          <div key={index} className="rl-request-item">
            <h2 className="rl-request-title">{request.requestName}</h2>
            <p className="rl-request-description">{request.description}</p>
            <div className="rl-request-info">
              <p><strong>Category:</strong> {request.category}</p>
              <p><strong>Location:</strong> Lat: {request.location.lat}, Lng: {request.location.lng}</p>
              <p><strong>Radius:</strong> {(request.radius / 1000).toFixed(2)} km</p>
              <p><strong>Duration:</strong> {request.duration} minutes</p>
            </div>
          </div>
        ))
      ) : (
        <div className="rl-no-requests">No requests found.</div>
      )}
    </div>
  );
};

export default RequestList;
