import styled, { createGlobalStyle } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const NavButton = styled.button`
  background-color: transparent;
  color: #333;
  border: 2px solid #ccc;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;

  &:hover {
    border-color: #888;
  }

  &:disabled {
    border-color: #eee;
    color: #aaa;
    cursor: not-allowed;
  }
`;

export const Separator = styled.div`
  padding: 24px 0 10px;
  margin: 32px 0 14px;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
`;

export const Dot = styled.span`
  margin-right: 20px;
  width: 3px;
  height: 3px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: block;
  box-sizing: border-box;

  &:last-child {
    margin-right: 0; /* Remove margin for the last dot */
  }
`;

// export const GlobalStyle = createGlobalStyle`
//   html, body {
//     margin: 0;
//     padding: 0;
//     height: 100%;
//     overflow: auto;
//     background-color: #ffffff;
//     font-family: 'Arial', sans-serif;
//     color: #333;
//   }

//   #root {
//     height: 100%;
//   }
// `;

export const Navbar = styled.nav`
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
`;

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (max-width: 551.98px) {
    margin: 12px;
  }
`;

export const Header = styled.header`
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

export const Title = styled.h1`
  letter-spacing: -0.462px;
  line-height: 52px;
  margin: 49.98px 0 32px;
  font-size: 42px;
  font-weight: 700;
  font-style: normal;
  color: rgb(36, 36, 36);
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 48px; /* Increased size for mobile */
    line-height: 56px; /* Adjusted line height for readability */
    margin: 32px 0 20px; /* Adjusted margins */
    font-weight: bold; /* Ensure bold weight */
  }

  @media (max-width: 551.98px) {
    font-size: 36px; /* Further increased size for smaller mobile */
    line-height: 44px; /* Further adjusted line height */
    margin: 24px 0 16px; /* Adjusted margins */
    letter-spacing: -0.003em; /* Adjusted for mobile readability */
  }
`;

export const Author = styled.div`
  color: #555;
  font-size: 1em;
  @media (max-width: 551.98px) {
    margin-top: 0.67em;
  }
`;

export const Date = styled.div`
  color: #aaa;
  font-size: 0.9em;
  @media (max-width: 551.98px) {
    line-height: 28px;
  }
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    width: 50%; /* Set width to 50% for larger screens */
  }
`;

export const Content = styled.div`
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  font-family: "source-serif-pro", Georgia, Cambria, "Times New Roman", Times,
    serif;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const Paragraph = styled.p`
  max-width: 680px;
  margin: 42.8px 24px -9.2px;
  letter-spacing: -0.06px;
  line-height: 32px;
  font-size: 20px;
  font-family: "source-serif-pro", Georgia, Cambria, "Times New Roman", Times,
    serif;
  font-style: normal;
  word-break: break-word;
  color: rgb(36, 36, 36);
  font-weight: 400;
  box-sizing: border-box;
  text-align: left;
`;

export const ReadMoreButton = styled.button`
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

// export const Footer = styled.footer`
//   text-align: center;
//   padding: 20px;
//   font-size: 0.9em;
//   color: #aaa;
//   border-top: 1px solid #ddd;
//   margin-top: 40px;
// `;

// export const BlogCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   border: 1px solid #e0e0e0; /* Subtle border */
//   border-radius: 12px; /* Slightly more rounded corners */
//   overflow: hidden;
//   margin-top: 40px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Refined shadow */
//   background-color: #ffffff;
//   transition: all 0.3s ease; /* Smooth transition for hover effect */

//   &:hover {
//     box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
//     transform: translateY(-4px); /* Slight lift effect */
//   }
// `;

// export const CardImage = styled.img`
//   width: 100%;
//   height: auto;
//   max-height: 160px; /* Slightly larger image height */
//   object-fit: cover;
//   border-bottom: 1px solid #e0e0e0; /* Subtle border for image */
// `;

// export const CardContent = styled.div`
//   padding: 20px; /* Increased padding for a more spacious look */
// `;

// export const CardTitle = styled.h2`
//   font-size: 1.5em; /* Slightly larger font size */
//   margin: 0 0 10px; /* Adjusted margin */
//   color: #333;
//   font-weight: 600; /* Slightly less bold */
// `;

// export const CardAuthor = styled.div`
//   font-size: 1em;
//   color: #555;
//   margin-top: 4px;
// `;

// export const CardDate = styled.div`
//   font-size: 0.9em;
//   color: #888; /* Lighter color for date */
//   margin-top: 2px;
// `;

export const CardDescription = styled.p`
  font-size: 1em;
  color: #666;
  margin: 12px 0; /* Increased margin for readability */
`;

export const CardFooter = styled.div`
  padding: 16px;
  text-align: center;
  border-top: 1px solid #e0e0e0; /* Subtle border for footer */
`;

export const StyledDate = styled.div`
  font-style: italic;
`;

export const GlobalStyle = styled.div`
  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
  }

  h2 {
    text-align: center;
    margin-top: 20px;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding: 20px;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  scroll-behavior: smooth;
  width: 100%;
  max-width: 100vw;

  &::-webkit-scrollbar {
    display: none;
  }
  & > *:first-child {
    margin-left: 50px; // Adjust the value to your liking
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const BlogCard = styled.div`
  flex: 0 0 280px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  transform-origin: center;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  width: 280px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    flex: 0 0 220px;
    height: 350px;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

export const CardContent = styled.div`
  padding: 15px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 1.3em;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`;

export const CardAuthor = styled.div`
  font-size: 1em;
  color: #666;
`;

export const CardDate = styled.div`
  font-size: 0.9em;
  color: #888;
`;

export const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  font-size: 0.9em;
  color: #aaa;
  border-top: 1px solid #ddd;
  margin-top: 40px;

  @media (max-width: 768px) {
    font-size: 0.8em;
    padding: 15px;
  }
`;
