import axios from "axios";
import React, { useState } from "react";

// Make sure axios is installed

const Test2 = () => {
  const [userName, setUserName] = useState("");
  const [location, setLocation] = useState(null);
  const [message, setMessage] = useState("");

  // Function to get user location
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocation({ error: "Unable to retrieve location." });
        }
      );
    } else {
      setLocation({ error: "Geolocation is not supported by this browser." });
    }
  };

  // Function to submit data to the backend
  const handleSubmit = async () => {
    if (!userName || !location) {
      setMessage("Please enter your name and get your location.");
      return;
    }

    try {
      const response = await axios.post(
        "http://localhost:5000/api/map/user-location",
        {
          userName,
          location: {
            lat: location.latitude,
            lng: location.longitude,
          },
        }
      );
      setMessage("Location successfully stored!");
      console.log(response.data);
    } catch (error) {
      console.error("Error saving location:", error);
      setMessage("Error saving location. Try again.");
    }
  };

  return (
    <div>
      <h2>Heyyy</h2>
      <div>
        <label>
          Name:
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </label>
      </div>
      <button onClick={handleGetLocation}>Get Current Location</button>

      {location && location.error && <p>{location.error}</p>}

      {location && !location.error && (
        <div>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </div>
      )}

      <button onClick={handleSubmit}>Submit</button>

      {message && <p>{message}</p>}
    </div>
  );
};

export default Test2;
