import React from "react";
import "./Test.css";
import img1 from "./images/society1.png";

const societyInfo = {
  name: "TechInnovators",
  description:
    "TechInnovators is a dynamic college society dedicated to fostering innovation and technological advancement among students. We organize workshops, hackathons, and tech talks to inspire and educate our members.",
  instaLink: "https://www.instagram.com/techinnovators",
  formLink: "https://forms.example.com/techinnovators-signup",
  siteLink: "https://techinnovators.college.edu",
  head: "Alex Johnson",
  coHeads: ["Sam Lee", "Jamie Smith"],
  members: ["Chris Wong", "Taylor Brown", "Jordan Patel", "Casey Kim"],
  events: [
    { name: "Annual Hackathon", date: "October 15-17, 2023" },
    { name: "AI Workshop Series", date: "Every Tuesday, 6-8 PM" },
    { name: "Tech Career Fair", date: "November 5, 2023" },
  ],
};

export default function CollegeSocietyPage() {
  return (
    <div className="soc-college-society-page">
      <div>
        <img src={img1} alt="TechInnovators Logo" className="-picture" />
        <h1 className="soc-h1">{societyInfo.name}</h1>
        <p>{societyInfo.description}</p>
      </div>

      <br />
      <section className="soc-links">
        <a
          href={societyInfo.instaLink}
          target="_blank"
          rel="noopener noreferrer"
          className="soc-button soc-button--insta"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
          </svg>
          Instagram
        </a>

        {/* <img src={img1} /> */}

        <a
          href={societyInfo.siteLink}
          target="_blank"
          rel="noopener noreferrer"
          className="soc-button soc-button--site"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="2" y1="12" x2="22" y2="12"></line>
            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
          </svg>
          Website
        </a>

        <a
          href={societyInfo.formLink}
          target="_blank"
          rel="noopener noreferrer"
          className="soc-button soc-button--join"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
          Join Us
        </a>
      </section>

      <div className="soc-info-grid">
        <section className="soc-card">
          <h2 className="soc-h2">Leadership</h2>
          <p>
            <strong>Head:</strong> {societyInfo.head}
          </p>
          <p>
            <strong>Co-Heads:</strong>
          </p>
          <ul className="soc-events-list">
            {societyInfo.coHeads.map((coHead, index) => (
              <li key={index}>
                <div>{coHead}</div>
              </li>
            ))}
          </ul>
        </section>

        <section className="soc-card">
          <h2 className="soc-h2">Members</h2>
          <ul className="soc-events-list">
            {societyInfo.members.map((member, index) => (
              <li key={index}>{member}</li>
            ))}
          </ul>
        </section>
      </div>

      {/* <section className="soc-card">
        <h2>Upcoming Events</h2>
        <ul className="soc-events-list">
          {societyInfo.events.map((event, index) => (
            <li key={index}>
              <span className="soc-event-name">{event.name}</span>
              <span className="soc-event-date">{event.date}</span>
            </li>
          ))}
        </ul>
      </section> */}
    </div>
  );
}
