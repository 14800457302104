import React, { useState, useEffect } from "react";
import "./Navbar.css";
import profile from "./images/navbar_profile.png";
import logo from "./images/r1.jpg";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("None");
  const [email, setEmail] = useState("None");
  const [showNotification, setShowNotification] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const navigate = useNavigate();

  // Fetching name and email from localStorage on component mount
  useEffect(() => {
    const storedName = localStorage.getItem("name") ?? "None";
    const storedEmail = localStorage.getItem("email") ?? "None";
    setName(storedName);
    setEmail(storedEmail);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");

    // Close the modal and show notification after a brief delay
    setModalOpen(false);
    // setTimeout(() => {
    //   setShowNotification(true);
    //   setTimeout(() => {
    //     setShowNotification(false);
    //   }, 3000); // Hide notification after 3 seconds
    // }, 300); // Allow a brief moment before showing the notification

    navigate("/");
  };

  return (
    <div className="navbar">
      {/* Logo aligned to the left */}
      <div className="navbar-title">
        <Link to="/homepage">
          <img src={logo} alt="Requesto Logo" className="navbar-logo" />
        </Link>
      </div>

      {/* Profile Icon aligned to the right */}
      <img
        src={profile}
        alt="Profile"
        className="navbar-logo1"
        onClick={toggleModal}
      />

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay-ap">
          <div className="modal-content-ap">
            <button className="modal-close" onClick={toggleModal}>
              &times;
            </button>
            <div className="profile-header">
              <div>
                <img src={profile} className="profile-picture" />
              </div>

              <h2>User Profile</h2>
            </div>
            <p>
              <strong>Name:</strong> {name}
            </p>
            <p>
              <strong>Email:</strong> {email}
            </p>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      )}
      {/* Notification */}
      {/* {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Logout Successfully
              </div>
            </div>
          </li>
        </ul>
      )} */}
    </div>
  );
};

export default Navbar;
