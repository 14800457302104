import AnimatedButtons from "../Buttons/Animations";
import Chatbutton from "../Buttons/Chatbutton";
import MinimalistMobileMenu from "../MinimalistMobileMenu";
import Navbar1 from "../Navbar";
import {
  ScrollContainer,
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  CardFooter,
  BlogCardContainer,
} from "../styles";
import { blogarray } from "./blogarray";
import blog4img from "./images/blog4.webp";
import blog2img from "./images/blog21.webp";
import blog3img from "./images/blog31.jpg";
import dtufoto from "./images/dtu.webp";
import people from "./images/people.webp";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

const BlogPage = () => {
  const navigate = useNavigate();
  const topRef = useRef(null);
  const scrollRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`); // Example URL structure, adjust as per your routing setup
  };
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div ref={topRef} style={{ height: "0", margin: "0", padding: "0" }}>
        <GlobalStyle />
        <Navbar1 />
        <Container>
          <Header>
            <HeaderImage src={dtufoto} alt="Header Image" />
            <Title>Welcome to DTU: A Glimpse into Your Future</Title>
            <Author>by Requesto</Author>
            <Date>July 18, 2024</Date>
          </Header>
          <Separator role="separator">
            <Dot />
            <Dot />
            <Dot />
          </Separator>

          <Content>
            <Paragraph>
              Welcome to DTU: A Glimpse into Your Future Welcome to DTU, a Tier
              1 engineering college that you might not have heard of while
              prepping for JEE. Congratulations on securing a spot here after
              all the hard work and stress. Your college life will be filled
              with expectations, crazy plans, and new goals. Let me give you a
              brief glimpse of what life inside DTU looks like.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              A Home Away From Home Most of you are from Delhi, but some are
              from outside. For those of you coming from afar, you'll get to
              experience hostel life at DTU. It's an adventure of its own. DTU
              is known for its relaxed academic pressure, except if you're in
              Electrical Engineering (EE). In your first year, you'll have
              plenty of time to explore new opportunities, meet new people, and
              join various societies that align with your interests—be it dance,
              music, entrepreneurship, or anything else. DTU has a plethora of
              societies, and the auditions for them start as soon as you enter
              the college. Trust me, it's a crazy competition you’ll face.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Hostel Life: The Real Deal For the girls, there's the VLB hostel,
              which everyone gets irrespective of the quota because of the low
              number of girls in our college. You get AC hostels from day one,
              with three people in a room in a big multi-storey building. For
              the boys, there are two options: Aryabhatta Hostel (three people)
              and Type 2 Hostel (five people), both without AC. It might be
              tough, but trust me, this one year without AC will be worth it.
              The hostel food has its phases—some things do taste well (though
              very few). If you have a Delhi quota, you might need to
              accommodate in PGs, which you can find just outside our college
              near SP.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              SP: The Hangout Spot SP, or Sutta Point, is the famous spot in
              front of our college where everyone chills. It serves food that's
              better than hostel and PG meals. Your classes will be from Monday
              to Friday, and every batch will have a different timetable.
              Classes are allocated by rearranging names alphabetically, so
              you’ll easily find people with common names in your class.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Exploring the Campus DTU boasts a beautiful 164-acre campus in
              Delhi, with hostels, a sports complex, canteens, and more. There
              are many eating places in DTU, the biggest being Raj Soin Hall
              Canteen with everything from oreo shakes to chole bhature. Rumor
              has it that Domino’s is opening there soon. There are three
              eateries in Raj Soin Hall, a normal canteen near OAT, a coffee
              place (Rayday), HPMC for small snacks, and our night canteen
              Mik-Mak, which is open till 4 AM. It's a great spot to chill,
              celebrate birthdays, or do anything you want. Boys can roam around
              the campus all night, but they can't go outside after 11 PM. Girls
              have to be in their rooms by 11 PM and can't go outside the campus
              after 9 PM.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Balancing Studies and Fun If you want to do something alongside
              your studies, like YouTube, a startup, or freelancing, you’ll have
              ample time in DTU to do so. This place is full of fun and
              opportunities. You can chill as much as you want or be dedicated
              to any tech society or other societies according to your
              interests. DTU is the epicenter of events. If you want to study
              hard, it can get challenging because there are events, functions,
              and fests almost every week, either from societies or
              colleges—just crazy fun.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Making Friends and Memories Overall, you’ll make a lot of friends
              here. There’s always something happening inside our college.
              There’s no ragging, and most seniors are super nice. You can meet
              them at unofficial and official freshers' events. I can promise
              you that you won't regret your four years here if you have fun,
              make friends, and stay connected to DTU.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Welcome to DTU! Your exciting journey begins now.
            </Paragraph>
          </Content>

          <br />
          <br />
          <br />
          <br />
          <ButtonContainer>
            <NavButton disabled>Previous</NavButton>
            <Link to="/blog2">
              <NavButton>Next</NavButton>
            </Link>
          </ButtonContainer>

          {/* <h2>Latest Blog Posts</h2>
          <ScrollContainer ref={scrollRef}>
            {blogarray.map((blog, index) => (
              <BlogCard
                key={blog.id}
                className="blog"
                style={{
                  transform: `scale(${activeIndex === index ? 1 : 0.85})`,
                }}
                onClick={() => handleReadMore(blog.id)}
              >
                <CardImage src={blog.image} alt={blog.title} />
                <CardContent>
                  <CardTitle>{blog.title}</CardTitle>
                  <CardAuthor>by {blog.author}</CardAuthor>
                  <CardDate>{blog.date}</CardDate>
                  <ReadMoreButton>Read More</ReadMoreButton>
                </CardContent>
              </BlogCard>
            ))}
          </ScrollContainer> */}
        </Container>
        <MinimalistMobileMenu />
        <Footer>&copy; 2024 Requesto. All rights reserved.</Footer>
      </div>
    </>
  );
};

export default BlogPage;
