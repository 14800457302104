import React, { useState, useRef, useEffect } from "react";
import "./Homepage.css";
import AcademicButton from "./Buttons/AcademicButton";
import AnimatedButtons from "./Buttons/Animations";
import Chatbutton from "./Buttons/Chatbutton";
import Inbox from "./Inbox";
import MinimalistMobileMenu from "./MinimalistMobileMenu";
import Navbar1 from "./Navbar";
import Navbar from "./Navbar";
import CustomRequestModal from "./RequestTemplates/CustomRequestModal";
import SellRequestModal from "./RequestTemplates/SellRequestModal";
import WelcomePopups from "./WelcomePopups";
import { blogarray } from "./blogs/blogarray";
import { eventarray } from "./events/eventarray";
import blog4img from "./images/blog4.webp";
import blog2img from "./images/blog21.webp";
// import blog2img from './images/blog21.webp'
import blog3img from "./images/blog31.jpg";
import dtufoto from "./images/dtu.webp";
import requesto from "./images/image141.png";
import or1 from "./orientation_1.jpg";
import or2 from "./orientation_2.jpg";
import or3 from "./orientation_3.jpg";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  ScrollContainer,
  CardFooter,
} from "./styles";
import ws from "./whatsapp.png";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  HelpingHand,
} from "lucide-react";
import ReactGA from "react-ga4";
import { Link, useNavigate } from "react-router-dom";

const societiesData = [
  {
    id: 1,
    name: "AIMS-DTU",
    description:
      "The society has significantly enhanced its reputation within the realms of AI and ML, demonstrated through its remarkable success at prestigious events like the Smart India Hackathon, where teams secured first-place victories for innovative solutions addressing traffic optimization and sentiment analysis. ",
    instaLink: "https://www.instagram.com/aimsdtu?igsh=bGJjeTFoa2s2aXZ5 ",
    imageSrc: require("./images/society1.png"),
  },
  {
    id: 2,
    name: "ROBOTICS-DTU",
    description:
      "The Society of Robotics at Delhi Technological University (SRDTU) is comprised of a dedicated group of students focused on fostering a robotics-friendly environment. Their primary aim is to develop innovative robotic applications that address societal challenges, such as security and automation of events.",
    instaLink: "https://www.instagram.com/srdtu_?igsh=MWFsZ295ZHd5aTV1bA==",
    imageSrc: require("./images/society2.png"),
  },
  {
    id: 3,
    name: "MADHURIMA",
    description:
      "The **Madhurima Music Society** at Delhi Technological University (DTU) is a vibrant community dedicated to cultivating a love for music among students. As the official music society of DTU, it provides a platform for aspiring musicians to express their creativity and hone their skills. ",
    instaLink:
      "https://www.instagram.com/madhurima.dtu?igsh=MWl0bzlwOXpqMjR3Zg==",
    imageSrc: require("./images/society3.png"),
  },
  {
    id: 4,
    name: "KALAKRITI",
    description: "kalakriti is the art society of DTU",
    instaLink: "https://www.instagram.com/kalakriti_dtu?igsh=bDRtZjFrbHBzZ2Ri",
    imageSrc: require("./images/society4.png"),
  },
  {
    id: 5,
    name: "PARCHHAYI",
    description: "Parchaayi is the fashion society of DTU",
    instaLink: "https://www.instagram.com/parchhayi.dtu?igsh=NjlnbDZhYWEzOGZk",
    imageSrc: require("./images/society5.png"),
  },
  {
    id: 6,
    name: "VIBE",
    description: "Vibe is the official dance society of DTU",
    instaLink:
      "https://www.instagram.com/vibe_dance_crew?igsh=MXN4cHJ3YnF5M2twbA==",
    imageSrc: require("./images/society6.png"),
  },
  {
    id: 7,
    name: "STEP DTU",
    description:
      "Step DTU is a vibrant community at Delhi Technological University focused on promoting happiness and harmony among its members. The society emphasizes well-being and spiritual awareness, aiming to foster a supportive environment through various initiatives. They engage in teaching, research, and collaboration with industry professionals to scientifically advance the art of happiness. The society organizes orientation sessions and health-related events to raise awareness, featuring motivational speakers who share personal insights to inspire members. Their commitment to creating a safe space for individuals of all genders and personalities further underscores their dedication to fostering a positive community.",
    instaLink: "https://www.instagram.com/step_dtu/",
    imageSrc: require("./images/society7.png"),
  },
  {
    id: 8,
    name: "IGTS",
    description:
      "The Indian Game Theory Society's DTU chapter is dedicated towards spreading awareness, knowledge & application of Game Theory.",
    instaLink: "https://www.instagram.com/igts.dtu/",
    imageSrc: require("./images/society8.png"),
  },
  {
    id: 9,
    name: "REFLECT DTU",
    description:
      "Reflect DTU has achieved official recognition as The Cinematography Society of DTU at Delhi Technological University, marking a significant milestone in its two-year journey. Founded by Shashank Anand and Ritik T., the society was created to offer a platform for students passionate about photography and cinematography. Despite challenges, the team, notably led by Vaibhav Arya, showcased resilience and commitment, overcoming obstacles to establish a lasting impact within the college community. Their ongoing efforts inspire change and excellence in the realm of visual storytelling.",
    instaLink: "https://www.instagram.com/reflect.dtu/",
    imageSrc: require("./images/society9.png"),
  },
];
const techteamData = [
  {
    id: 1,
    name: "Defianz",
    description: "Tech team for building cars",
    instaLink:
      "https://www.instagram.com/teamdefianzracing_dtusdc?igsh=ZjRleXVlcDN6NHd6 ",
    imageSrc: require("./images/teachteam1.jpg"),
  },
  {
    id: 2,
    name: "DTU ROCKETRY",
    description: "Tech team for building rockets",
    instaLink:
      "https://www.instagram.com/rocketrydtu?igsh=MTVvdGt4MWNzZ211Ng==",
    imageSrc: require("./images/teachteam2.png"),
  },
  {
    id: 3,
    name: "UAS DTU",
    description: "Tech team for building ariel vehicles",
    instaLink: "https://www.instagram.com/uas_dtu?igsh=MW94bTdydjM4Y3hmcg==",
    imageSrc: require("./images/teachteam3.jpg"),
  },
  {
    id: 4,
    name: "INFERNO",
    description: "Tech team for building rovers",
    instaLink: "https://www.instagram.com/uas_dtu?igsh=MW94bTdydjM4Y3hmcg==",
    imageSrc: require("./images/techteam4.jpg"),
  },
  {
    id: 5,
    name: "UGV",
    description: "Tech team for building underwater vehicles",
    instaLink: "https://www.instagram.com/uas_dtu?igsh=MW94bTdydjM4Y3hmcg==",
    imageSrc: require("./images/techteam5.jpg"),
  },
  {
    id: 6,
    name: "AUV",
    description: "Tech team for building vehicles",
    instaLink: "https://www.instagram.com/uas_dtu?igsh=MW94bTdydjM4Y3hmcg==",
    imageSrc: require("./images/techteam6.jpg"),
  },
];

function Homepage() {
  const scrollRef = useRef(null);
  const blogRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showEmailVerification, setShowEmailVerification] = useState(true);
  const [showCustomAlert, setShowCustomAlert] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("email") || null);
  const [isVerified, setIsVerified] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showWelcomePopups, setShowWelcomePopups] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const heroRef = useRef(null);
  const [isCustomRequestModalOpen, setIsCustomRequestModalOpen] =
    useState(false); // State for MicroLearn modal
  const [isSellRequestModalOpen, setIsSellRequestModalOpen] = useState(false); // State for MicroLearn modal
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [eventPopUp, setEventPopUp] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // To store the selected event details

  const openPopup = (society) => {
    setSelectedSociety(society);
  };

  const closePopup = () => {
    setSelectedSociety(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowMenu(!entry.isIntersecting);
      },
      { threshold: 0 } // Fires as soon as the hero section leaves the viewport
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }
    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  const learnMore = () => {
    if (blogRef.current) {
      blogRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    // Check if there's a sign-in notification flag
    const signInNotificationFlag = localStorage.getItem(
      "showSignInNotification"
    );
    if (signInNotificationFlag === "true") {
      setShowNotification(true);
      setShowWelcomePopups(true);
      localStorage.removeItem("showSignInNotification"); // Clear the flag
      localStorage.setItem("flag", true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2500); // Hide notification after 2.5 seconds
    }
    const preloadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => setImageLoaded(true);
    };

    preloadImage(blog2img);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmail(decodedToken.user.email);
      setIsVerified(decodedToken.user.isVerified);
    }
  }, []);
  useEffect(() => {
    const trackPageView = () => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    };

    trackPageView(); // Track initial load
    window.addEventListener("popstate", trackPageView); // Track on route changes

    return () => {
      window.removeEventListener("popstate", trackPageView);
    };
  }, []);

  const handleHeroClick = () => {
    setClickCount((prevCount) => {
      if (prevCount + 1 === 6) {
        setShowModal(true);
        return 0;
      }
      return prevCount + 1;
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === "123456") {
      navigate("/blog5");
      setShowModal(false);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  const handleDismissEmailVerification = () => {
    setShowEmailVerification(false);
  };

  const handleDismissCustomAlert = () => {
    setShowCustomAlert(false);
  };

  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    }); // Example URL structure, adjust as per your routing setup
  };

  const handleSendOTP = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/send-otp`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        setShowOtpPopup(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/verify-otp`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, otp }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Store the new token in local storage
        localStorage.setItem("token", data.token);

        // Update the state to reflect verification status
        setIsVerified(true);

        // Close the OTP popup
        setShowOtpPopup(false);

        // Optionally dismiss the custom alert
        setShowCustomAlert(false);
      } else {
        // Handle verification failure (optional)
        console.error("OTP verification failed:", data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  useEffect(() => {
    const fetchParticipation = async () => {
      const name = localStorage.getItem("name");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/academic-request/participation`,
          {
            params: {
              email: email,
              name: name,
            },
          }
        );
        // console.log("Sent Requests:", response.data.sentRequests);
        // console.log("Accepted Requests:", response.data.acceptedRequests);
        // console.log("Applied Requests:", response.data.appliedRequests);
      } catch (error) {
        console.error("Error fetching participation data:", error);
      }
    };

    fetchParticipation();
  }, [email]);

  // Ensure 'email' is properly defined before this useEffect runs

  useEffect(() => {
    const handleScroll = () => {
      const blogs = scrollRef.current.querySelectorAll(".blog");
      const viewportWidth = scrollRef.current.offsetWidth;
      const screenCenter = viewportWidth / 2;

      blogs.forEach((blog, index) => {
        const blogRect = blog.getBoundingClientRect();
        const blogCenter = (blogRect.left + blogRect.right) / 2;
        const distanceFromCenter = Math.abs(blogCenter - screenCenter);
        const maxDistance = screenCenter;

        const scale = Math.max(0.85, 1 - distanceFromCenter / maxDistance);
        blog.style.transform = `scale(${scale})`;

        if (distanceFromCenter < blogRect.width / 2) {
          setActiveIndex(index);
        }
      });
    };

    const scrollContainer = scrollRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set scale

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleContactUs = () => {
    // console.log("Hello");
    setOpenContactUs(true);
  };

  const closeContactUs = () => {
    setOpenContactUs(false);
  };

  const closeEventPopUp = () => {
    setEventPopUp(false);
  };

  const handleEventPopUp = (event) => {
    setSelectedEvent(event); // Store the selected event
    setEventPopUp(true);
  };

  return (
    <div className="a1Blog">
      {showWelcomePopups && <WelcomePopups />}

      <Navbar1 />
      <Inbox />

      {showCustomAlert && !isVerified && (
        <div className="a1custom-alert">
          You should verify your email now.
          <button
            type="button"
            className="a1verify-button"
            onClick={handleSendOTP}
          >
            Verify Now!
          </button>
          <button
            type="button"
            className="a1close-button"
            aria-label="Close"
            onClick={handleDismissCustomAlert}
          >
            &times;
          </button>
        </div>
      )}

      <section ref={heroRef}>
        <div className="a1topsection">
          {/* <h1>Welcome to Requesto</h1>
          <p>Explore Everything You Need to Know About DTU</p> */}
          <div className="homepage-options">
            <button onClick={() => navigate("/assignmentrequests")}>
              View Requests
            </button>
            <button onClick={() => setIsCustomRequestModalOpen(true)}>
              Post Request
            </button>
            <button onClick={() => setIsSellRequestModalOpen(true)}>
              Sell something
            </button>
          </div>
        </div>
      </section>
      <section
        className="a1hero"
        id="home"
        // onClick={handleHeroClick}
        disabled
        style={{ backgroundImage: `url(${blog2img})` }}
      >
        <div className="a1hero-content">
          <h1>Welcome to Requesto</h1>
          <p>Explore Everything You Need to Know About DTU</p>
          <button className="a1cta" onClick={learnMore}>
            Learn More
          </button>
        </div>
      </section>

      <section className="a1about" id="about">
        <div className="a1container">
          <h2>About DTU</h2>
          <p>
            Delhi Technological University (DTU) is a premier institution in
            India, renowned for its cutting-edge research and excellent
            education.
          </p>
          <p>
            Experience a legacy of innovation, exceptional faculty, and a
            vibrant student community.
          </p>
        </div>
      </section>
      <div className="a1fresher-alert">
        <strong>Attention Freshers: Society Recruitment Update</strong>
        <ul>
          <li>
            <strong>Settle In First</strong>: Focus on getting settled into your
            classes. After a few days, you’ll start noticing posters and
            advertisements for various societies around the campus.
          </li>
          <li>
            <strong>Recruitment Forms</strong>: Most societies will share their
            recruitment forms in the WhatsApp groups you’ve joined, including
            your class group. If you haven’t joined these groups yet, be sure to
            do so, as they provide important updates, including society
            information.
          </li>
          <li>
            <strong>Auditions and Selections</strong>: Each society conducts
            auditions and requires you to fill out recruitment forms. Entry is
            based on selection—you cannot join directly.
          </li>
          <li>
            <strong>Our Advice</strong>: Attend every audition that interests
            you and give it your best shot!
          </li>
        </ul>
      </div>

      <ScrollContainer ref={scrollRef}>
        {eventarray.map((blog, index) => (
          <BlogCard
            key={blog.id}
            className="blog"
            style={{
              transform: `scale(${activeIndex === index ? 1 : 0.85})`,
            }}
            onClick={() => handleEventPopUp(blog)} // Pass the clicked event data here
          >
            <CardImage src={blog.image} alt={blog.title} />
            <CardContent>
              <CardTitle>{blog.title}</CardTitle>
              <CardAuthor>by {blog.author}</CardAuthor>
              <CardDate>{blog.date}</CardDate>
              <ReadMoreButton>Event Details</ReadMoreButton>
            </CardContent>
          </BlogCard>
        ))}
      </ScrollContainer>

      <section className="a1orientation" id="orientation">
        <div className="a1container">
          <h2>Attractions of DTU</h2>
          <div className="a1orientation-gallery">
            {/* <img src={or1} alt="Orientation 1" className="a1orientation-image" /> */}
            {/* <img src={or2} alt="Orientation 2" className="a1orientation-image" /> */}
            <img
              src={or3}
              alt="Orientation 3"
              className="a1orientation-image"
            />
          </div>
        </div>
      </section>
      <section className="societies-section" id="societies">
        <div className="societies-section">
          <h2>DTU Societies</h2>
          <div className="societies-gallery">
            {societiesData.map((society) => (
              <div
                key={society.id}
                className="society-image"
                onClick={() => openPopup(society)}
              >
                <img src={society.imageSrc} alt={society.name} />
              </div>
            ))}
          </div>

          {selectedSociety && (
            <div className="soc-popup-overlay" onClick={closePopup}>
              <div
                className="soc-popup-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button className="soc-close-button" onClick={closePopup}>
                  ×
                </button>
                <img
                  src={selectedSociety.imageSrc}
                  alt={selectedSociety.name}
                />
                <h3>{selectedSociety.name}</h3>
                <p>{selectedSociety.description}</p>
                <a
                  href={selectedSociety.instaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="societies-section" id="societies">
        <div className="societies-section">
          <h2>Tech Teams</h2>
          <div className="societies-gallery">
            {techteamData.map((society) => (
              <div
                key={society.id}
                className="society-image"
                onClick={() => openPopup(society)}
              >
                <img src={society.imageSrc} alt={society.name} />
              </div>
            ))}
          </div>

          {selectedSociety && (
            <div className="soc-popup-overlay" onClick={closePopup}>
              <div
                className="soc-popup-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button className="soc-close-button" onClick={closePopup}>
                  ×
                </button>
                <img
                  src={selectedSociety.imageSrc}
                  alt={selectedSociety.name}
                />
                <h3>{selectedSociety.name}</h3>
                <p>{selectedSociety.description}</p>
                <a
                  href={selectedSociety.instaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
            </div>
          )}
        </div>
      </section>

      <section id="blog" ref={blogRef}>
        <div className="a1container">
          <h2>Latest Blog Posts</h2>
          <ScrollContainer ref={scrollRef}>
            {blogarray.map((blog, index) => (
              <BlogCard
                key={blog.id}
                className="blog"
                style={{
                  transform: `scale(${activeIndex === index ? 1 : 0.85})`,
                }}
                onClick={() => handleReadMore(blog.id)}
              >
                <CardImage src={blog.image} alt={blog.title} />
                <CardContent>
                  <CardTitle>{blog.title}</CardTitle>
                  <CardAuthor>by {blog.author}</CardAuthor>
                  <CardDate>{blog.date}</CardDate>
                  <ReadMoreButton>Read More</ReadMoreButton>
                </CardContent>
              </BlogCard>
            ))}
          </ScrollContainer>
        </div>
      </section>

      {/* <section className="a1whatsapp" id="whatsapp">
        <div className="a1container">
          <h2>Connect with Us on WhatsApp</h2>
          <p>Scan the QR code below to join our exclusive whatsapp group.</p>
          <img src={ws} alt="WhatsApp QR Code" className="a1whatsapp-qr" />
        </div>
      </section>

      <section className="a1connect" id="connect">
        <div className="a1container">
          <h2>Get in Touch</h2>
          <p>
            Want to connect with a senior or get more information about DTU?
            Reach out to us!
          </p>
        </div>
      </section> */}

      {/* <AnimatedButtons /> */}
      {showMenu && <MinimalistMobileMenu />}
      <footer className="a1-footer">
        <div className="a1-container">
          <div className="a1-section">
            <h2 className="a1-title">Requesto</h2>
            <div className="a1-nav">
              {/* <Link href="#" className="a1-link">
                Home
              </Link> */}
              <Link to="/mhpg#pricing" className="a1-link">
                About
              </Link>
              {/* <Link href="#" className="a1-link">
                Pricing
              </Link> */}
              <Link href="#" className="a1-link" onClick={handleContactUs}>
                Contact
              </Link>
            </div>
          </div>

          <div className="a1-socials">
            {/* <Link href="#" className="a1-social-link">
              <Facebook size={24} />
              <span className="a1-sr-only">Facebook</span>
            </Link>
            <Link href="#" className="a1-social-link">
              <Twitter size={24} />
              <span className="a1-sr-only">Twitter</span>
            </Link> */}
            <a
              href="https://www.instagram.com/requesto.in?igsh=cHJxdnh6OTB6azVj"
              className="a1-social-link"
            >
              <Instagram size={24} />
              <span className="a1-sr-only">Instagram</span>
            </a>
            <a
              href="https://www.linkedin.com/company/103684563/"
              className="a1-social-link"
            >
              <Linkedin size={24} />
              <span className="a1-sr-only">LinkedIn</span>
            </a>
          </div>

          <div className="a1-footer-bottom">
            <p>&copy; Requesto. All rights reserved.</p>
            <div className="a1-footer-nav">
              <Link to="/privacy" className="a1-link">
                Privacy Policy
              </Link>
              <Link to="/tc" className="a1-link">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </footer>
      {showModal && (
        <div className="a1modal">
          <div className="a1modal-content">
            <span className="a1close" onClick={handleCloseModal}>
              &times;
            </span>
            <h2>Enter Password</h2>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="a1password-input"
            />
            <button
              onClick={handlePasswordSubmit}
              className="a1submit-password"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {showOtpPopup && (
        <div className="a1modal">
          <div className="a1modal-content">
            <span className="a1close" onClick={() => setShowOtpPopup(false)}>
              &times;
            </span>
            <h2>Enter OTP</h2>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="a1otp-input"
              placeholder="Enter OTP"
            />
            <button onClick={handleVerifyOTP} className="a1verify-otp">
              Verify OTP
            </button>
          </div>
        </div>
      )}
      {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Signed in Successfully
              </div>
            </div>
          </li>
        </ul>
      )}

      {openContactUs && (
        <div className="soc-popup-overlay" onClick={closeContactUs}>
          <div
            className="soc-popup-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="soc-close-button" onClick={closeContactUs}>
              ×
            </button>
            <img
              src={requesto}
              alt="a"
              className="profile-picture"
              style={{ height: "80px" }}
            />
            <h3>Requesto Team</h3>
            <br />
            <br />
            <p>+91 9627287643</p>
            <p>+91 8824014907</p>
            <p>applyatrequesto@gmail.com</p>
            {/* <a
              href={selectedSociety.instaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a> */}
          </div>
        </div>
      )}
      {eventPopUp && selectedEvent && (
        <div className="soc-popup-overlay" onClick={closeEventPopUp}>
          <div
            className="soc-popup-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="soc-close-button" onClick={closeEventPopUp}>
              ×
            </button>
            <img
              src={selectedEvent.image}
              alt="a"
              className="profile-picture"
              style={{ height: "80px" }}
            />
            <h3>{selectedEvent.title}</h3>
            <p>{selectedEvent.description}</p>{" "}
            {/* Display the description here */}
            <br />
            {/* <p>+91 9627287643</p>
            <p>+91 8824014907</p> */}
            <p>applyatrequesto@gmail.com</p>
          </div>
        </div>
      )}

      <CustomRequestModal
        showModal={isCustomRequestModalOpen}
        closeModal={() => setIsCustomRequestModalOpen(false)}
      />
      <SellRequestModal
        showModal={isSellRequestModalOpen}
        closeModal={() => setIsSellRequestModalOpen(false)}
      />
    </div>
  );
}

export default Homepage;
