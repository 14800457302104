import AnimatedButtons from "../Buttons/Animations";
import MinimalistMobileMenu from "../MinimalistMobileMenu";
import Navbar1 from "../Navbar";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  CardFooter,
} from "../styles";
import { blogarray } from "./blogarray";
import blog4img from "./images/blog4.webp";
import blog2img from "./images/blog21.webp";
import blog3img from "./images/blog31.jpg";
import dtufoto from "./images/dtu.webp";
import ninja from "./images/ninja.webp";
import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Blog7 = () => {
  const navigate = useNavigate();
  const topRef = useRef(null);

  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`);
  };

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div ref={topRef} style={{ height: "0", margin: "0", padding: "0" }}>
        <GlobalStyle />
        <Navbar1 />
        <Container>
          <Header>
            <HeaderImage src={ninja} alt="Header Image" />
            <Title>
              DTU After Dark: Your Guide to the Wild Ride of College Nightlife
            </Title>
            <Author>by Requesto</Author>
            <Date>August 12, 2024</Date>
          </Header>

          <Separator role="separator">
            <Dot />
            <Dot />
            <Dot />
          </Separator>

          <Content>
            <Paragraph>
              Hey there, future DTU students (and maybe even some curious
              souls)! Are you wondering what it's like to roam a 167-acre campus
              bathed in moonlight? Well, buckle up because DTU's nightlife is a
              whirlwind of freedom, fun, and maybe a few mischievous monkeys!
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              First things first: This blog is split into two parts, ninjas
              (boys) and queens (girls), because the rules differ slightly. But
              fear not, both sides get to experience the magic of DTU after
              dark.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>Calling all Ninjas!</Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Your nights at DTU are an epic adventure. Imagine this: it's late,
              you're crashing in your hostel room with your trusty slow internet
              (hey, that's part of the experience, right?), but you're not ready
              for sleep. Not even close. Because at DTU, the night is your
              playground.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Campus Cruiser: This entire 167-acre beauty is yours to explore.
              Feeling peckish? Order food online and pick it up at the gate. No
              curfew means endless possibilities for midnight cravings.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Hostel Hustle: DTU nights are all about camaraderie. Pop into your
              friend's room for a chat, laughter, and maybe even a strategy
              session for the next game night.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Sports Spotlight: Feeling energetic? Hit the sports complex! Just
              remember to bring your own equipment – it's BYOB (Bring Your Own
              Ball). You'll always find someone else ready to rally under the
              stars.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              The Great Escape: Need to blow off some steam? We've all been
              there. DTU's beautiful, green labyrinth of roads is perfect for a
              spontaneous adventure. Just remember, be respectful and keep
              things civil (no disturbing the peace, ninjas!). Pro tip:
              birthdays are legendary at the Open Air Theatre (OAT). Picture
              this: cake, friends, and a playful beating with a belt (all in
              good fun, of course) followed by celebratory treats at the
              legendary Mik Mak (more on that later).
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Mik Mak Magic: This night canteen is the heart of DTU's after-dark
              scene. It's always buzzing with activity, and the menu is a
              late-night dream – parathas, steaming maggi, fluffy omelets, and
              refreshing cold drinks.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Senior Squad: Keep an eye out for our amazing seniors. They'll be
              zipping around campus on their scooters, bikes, and cars,
              radiating pure joy. They're living proof that college is a blast!
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>The Queens Rule the Night Too!</Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Ladies, DTU nights are pretty awesome for you too. Here's the
              scoop:
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Girl Gang Goals: You and your girl squad can roam the campus
              freely until 11 PM, which is pretty sweet compared to other Delhi
              colleges. Hit Mik Mak for a catch-up session or grab some ice
              cream – the perfect way to end the day. There might be times when
              girls' hostel curfew gets stricter (down to 9 PM), but these are
              temporary situations, and you'll still have a good chunk of the
              night to unwind.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Chilling at the Commons: Both OAT and Mini OAT are open for you to
              explore and relax in, as long as you're back in the hostel by 11
              PM. Remember, safety first!
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Ninja Nightmares? Don't worry, the girls' hostel has an attendance
              system that ensures your safety. Just make sure you sign in by 11
              PM to avoid a friendly reminder from the warden.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>General Nightlife Guidelines:</Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Peace Out, Noise: We love a good time, but it's important to be
              mindful of the residential area near the college. Keep the noise
              down to avoid complaints.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Beware of the Furry (and Feisty) Foes: Yes, you read that right.
              DTU has monkeys and packs of dogs that roam the campus at night.
              Be cautious and avoid getting too close to them. They might look
              cute, but they can be unpredictable. If you see them, it's best to
              keep your distance and enjoy the night from afar.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Late-Night Study Spots: If you're the type who finds inspiration
              in the silence of the night, DTU has got you covered. The library
              may close, but the hostel common rooms are open for late-night
              study sessions. Just grab your books, find a cozy corner, and let
              the night fuel your academic ambitions.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Nighttime Cafes: In addition to Mik Mak, there are a few other
              spots where you can grab a late-night bite or a cup of coffee.
              While these might not be official DTU establishments, they’re
              popular among students looking for a break from the hostel or a
              change of scenery during study breaks.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Parting Thoughts: DTU’s nightlife is an experience like no other.
              Whether you’re a night owl looking for an adventure, a sports
              enthusiast ready for a late-night game, or a student aiming to ace
              that next exam, there’s something for everyone. Just remember to
              respect the campus rules, stay safe, and enjoy every moment.
              College life is short, and these nights will be some of your best
              memories!
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              So, what are you waiting for? Embrace the night, explore the
              campus, and make the most of your time at DTU. Who knows what
              adventures await you under the stars?
            </Paragraph>
          </Content>
        </Container>

        <Footer>
          <ButtonContainer>
            <NavButton onClick={() => navigate("/blog4")}>Previous</NavButton>
            <NavButton disabled>Next</NavButton>
          </ButtonContainer>
          {/* <h2>Related Blogs</h2> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {/* {blogarray.map((blog) => (
              <BlogCard key={blog.id}>
                <CardImage src={blog.image} alt={blog.title} />
                <CardContent>
                  <CardTitle>{blog.title}</CardTitle>
                  <CardAuthor>{blog.author}</CardAuthor>
                  <CardDate>{blog.date}</CardDate>
                  <CardDescription>{blog.description}</CardDescription>
                  <CardFooter>
                    <Link to={`/blog${blog.id}`}>
                      <ReadMoreButton>Read More</ReadMoreButton>
                    </Link>
                  </CardFooter>
                </CardContent>
              </BlogCard>
            ))} */}
          </div>
        </Footer>

        <ButtonContainer>
          <MinimalistMobileMenu />
        </ButtonContainer>
      </div>
    </>
  );
};

export default Blog7;
