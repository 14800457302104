import { CheckCircle, Users, Search } from "lucide-react";
import React, { useState, useEffect, useRef } from "react";
import "./MobileHomepage.css";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const MobileHomepage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const animateOnScroll = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-hpg");
        }
      });
    };

    const observer = new IntersectionObserver(animateOnScroll, {
      root: null,
      threshold: 0.1,
    });

    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="requesto-homepage-hpg">
      <Navbar />
      {/* <header className="header-hpg">
        <nav className="nav-hpg container-hpg">
          <a href="#" className="logo-hpg">
            Requesto
          </a>
          <div className="nav-links-hpg">
            <a href="#how-it-works">How It Works</a>
            <a href="#features">Features</a>
            <a href="#pricing">Pricing</a>
            <a href="#sign-in">Sign In</a>
          </div>
          <button
            className="menu-toggle-hpg"
            onClick={() => setMobileMenuOpen(true)}
          >
            ☰
          </button>
        </nav>
      </header> */}

      {mobileMenuOpen && (
        <div className="mobile-menu-hpg">
          <button
            className="close-menu-hpg"
            onClick={() => setMobileMenuOpen(false)}
          >
            ✕
          </button>
          <a href="#how-it-works" onClick={() => setMobileMenuOpen(false)}>
            How It Works
          </a>
          <a href="#features" onClick={() => setMobileMenuOpen(false)}>
            Features
          </a>
          <a href="#pricing" onClick={() => setMobileMenuOpen(false)}>
            Pricing
          </a>
          <a href="#sign-in" onClick={() => setMobileMenuOpen(false)}>
            Sign In
          </a>
        </div>
      )}

      <main>
        <section className="hero-hpg" ref={topRef}>
          <div className="container-hpg">
            <br />
            <br />
            <br />
            <h1>Post Requests, Complete Tasks, Earn Money</h1>
            <p>
              Requesto connects people who need tasks done with those who can
              complete them. Post your request or earn money by fulfilling
              others' needs.
            </p>
            <br />
            <div className="cta-buttons-hpg">
              <a href="#" className="button-hpg button-primary-hpg">
                Get Started
              </a>
              <a href="#" className="button-hpg button-secondary-hpg">
                Learn More
              </a>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="how-it-works-hpg">
          <div className="container-hpg">
            <h2>How It Works</h2>
            <div className="steps-hpg">
              <div className="step-hpg">
                <Search className="step-icon-hpg" />
                <h3>Post a Request</h3>
                <p>
                  Describe your task, set a budget, and post it for our
                  community to see.
                </p>
              </div>
              <div className="step-hpg">
                <Users className="step-icon-hpg" />
                <h3>Get Matched</h3>
                <p>Qualified individuals will offer to complete your task.</p>
              </div>
              <div className="step-hpg">
                <CheckCircle className="step-icon-hpg" />
                <h3>Task Completed</h3>
                <p>
                  Once the task is done, payment is released, and you can leave
                  a review.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="video-section-hpg">
          <div className="container-hpg">
            <h2>See Requesto in Action</h2>
            <div className="video-container-hpg">
              <iframe
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="Requesto Demo Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section> */}

        <section id="features" className="features-hpg">
          <div className="container-hpg">
            <h2>Key Features</h2>
            <div className="feature-cards-hpg">
              <div className="feature-card-hpg">
                <h3>Secure Payments</h3>
                <p>
                  Our escrow system ensures that funds are only released when
                  both parties are satisfied.
                </p>
              </div>
              <div className="feature-card-hpg">
                <h3>Verified Users</h3>
                <p>
                  All users go through a verification process to ensure a safe
                  and trustworthy community.
                </p>
              </div>
              <div className="feature-card-hpg">
                <h3>24/7 Support</h3>
                <p>
                  Our customer support team is always available to help with any
                  issues or questions.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonials-hpg">
          <div className="container-hpg">
            <h2>What Our Users Say</h2>
            <div className="testimonial-cards-hpg">
              <div className="testimonial-card-hpg">
                <img
                  src="/placeholder-avatar-1.jpg"
                  alt="User 1"
                  className="testimonial-avatar-hpg"
                />
                <h3>User 1</h3>
                <p>
                  "Requesto has been a game-changer for me. I've been able to
                  earn extra income by completing tasks in my free time."
                </p>
                <div className="stars-hpg">★★★★★</div>
              </div>
              <div className="testimonial-card-hpg">
                <img
                  src="/placeholder-avatar-2.jpg"
                  alt="User 2"
                  className="testimonial-avatar-hpg"
                />
                <h3>User 2</h3>
                <p>
                  "I love how easy it is to find help for my tasks. The platform
                  is user-friendly and the community is great!"
                </p>
                <div className="stars-hpg">★★★★★</div>
              </div>
              <div className="testimonial-card-hpg">
                <img
                  src="/placeholder-avatar-3.jpg"
                  alt="User 3"
                  className="testimonial-avatar-hpg"
                />
                <h3>User 3</h3>
                <p>
                  "As a student, Requesto has been an excellent way to make some
                  extra money. Highly recommended!"
                </p>
                <div className="stars-hpg">★★★★★</div>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" className="pricing-hpg">
          <div className="container-hpg">
            <h2>Simple, Transparent Pricing</h2>
            <div className="pricing-cards-hpg">
              <div className="pricing-card-hpg">
                <h3>Basic</h3>
                <div className="price-hpg">Rs 0/mo</div>
                <ul className="pricing-features-hpg">
                  <li>Post 1 request at a time</li>
                  <li>Accept 1 request at a time</li>
                  <li>Apply at 3 request at a time</li>
                  <li>Basic support</li>
                </ul>
                <a href="#" className="button-hpg button-primary-hpg">
                  Choose Basic
                </a>
              </div>
              <div className="pricing-card-hpg pricing-card-highlighted">
                <h3 className="highlighted-title">Requesto+</h3>
                {/* <p>Perfect for growing businesses</p> */}
                <div className="price-hpg">
                  <span className="price-old">Rs 99/mo</span> <br />
                  <span className="price-new">Rs 29/mo</span>
                </div>
                <ul className="pricing-features-hpg">
                  <li>Post Unlimited requests</li>
                  <li>Apply/Accept Unlimited requests</li>
                  <li>Priority support</li>
                  {/* <li>Analytics dashboard</li> */}
                </ul>
                <a href="#" className="button-hpg button-primary-hpg">
                  Choose Requesto+
                </a>
              </div>
              <div className="pricing-card-hpg">
                <h3 className="highlighted-title">RequestoX</h3>
                {/* <p>Perfect for large organizations</p> */}
                <div className="price-hpg">
                  <span className="price-old">Rs 199/mo</span> <br />
                  <span className="price-new">Rs 99/mo</span>
                </div>
                <ul className="pricing-features-hpg">
                  {/* <li>Custom solutions</li>
                  <li>Dedicated account manager</li>
                  <li>Custom integrations</li> */}
                  <li>Posting in different servers</li>
                  <li>Whatsapp notifications</li>
                  <li>All features of Requesto+</li>
                </ul>
                <a href="#" className="button-hpg button-primary-hpg">
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="cta-hpg">
          <div className="container-hpg">
            <h2>Ready to Get Started?</h2>
            <p>
              Join Requesto today and start earning or get your tasks done
              efficiently.
            </p>
            {/* <form
              className="signup-form-hpg"
              onSubmit={(e) => {
                e.preventDefault();
                alert("Thank you for signing up! We'll be in touch soon.");
              }}
            >
              <input type="email" placeholder="Enter your email" required />
              <button type="submit">Sign Up</button>
            </form>
            <br />
            <br />
            <p className="terms-hpg">
              By signing up, you agree to our Terms & Conditions
            </p> */}
          </div>
        </section>
      </main>

      <footer className="footer-hpg">
        <div className="container-hpg">
          <p>&copy; 2023 Requesto. All rights reserved.</p>
          <div className="footer-links-hpg">
            <Link to="/tc">Terms of Service</Link>
            <Link to="/privacy">Privacy</Link>
            {/* <a href="#">About</a> */}
            {/* <a href="#">Contact</a> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MobileHomepage;
