// events
import dandiya from "./images/dandiya.jpeg";

export const eventarray = [
  {
    id: "1",
    title: "🎉 Get Ready for Dandiya Night! 🎉 ",
    author: "Requesto",
    date: "October 9, 2024",
    image: dandiya,
    description:
      "An unforgettable evening of dance, music, and fun!  Organized by CC, DTU  10th & 11th October | 6:00 PM Onwards  Aryabhatta ground. Don't miss out on the vibrant celebration! Dress up, grab your dandiya sticks, and let's dance the night away! 💃🕺",
  },

  // Add more events as needed
];
