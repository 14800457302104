import Chatbutton from "./Buttons/Chatbutton";
import Navbar1 from "./Navbar";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  Content,
  Paragraph,
  Footer,
} from "./styles";
import React, { useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";

const TC = () => {
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div ref={topRef} style={{ height: "0", margin: "0", padding: "0" }}>
        <GlobalStyle />
        <Container>
          <Header>
            <Title>Terms and Conditions</Title>
          </Header>
          <Content>
            <Paragraph>
              These terms and conditions outline the rules and regulations for
              the use of Requesto Website, located at{" "}
              <a
                href="https://requesto.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://requesto.in
              </a>
              .
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use requesto.in if you do not agree
              to take all of the terms and conditions stated on this page.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Website’s terms and conditions. "The
              Website", "Ourselves", "We", "Our" and "Us", refers to our team
              maintaining this website. "Party", "Parties", or "Us", refers to
              both the Client and ourselves. All terms refer to the offer,
              acceptance and consideration of payment necessary to undertake the
              process of our assistance to the Client in the most appropriate
              manner for the express purpose of meeting the Client’s needs in
              respect of provision of the Website's stated services. Any use of
              the above terminology or other words in the singular, plural,
              capitalization and/or he/she or they, are taken as interchangeable
              and therefore as referring to same.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>Cookies</strong>
            </Paragraph>
            <Paragraph>
              We employ the use of cookies. By accessing requesto.in, you agreed
              to use cookies in agreement with the Requesto’s Privacy Policy.
              Most interactive websites use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>ABOUT</strong>
            </Paragraph>
            <Paragraph>
              Parts of this website offer an opportunity for users to post any
              type of request/need/problem which can be seen by other users
              along with us. These requests include any type of requests like
              you need to rent something, freelancing opportunity, delivery,
              microlearning, academics, etc. Requesto also allows users to chat
              with each other on our website. Requesto does not filter, edit,
              publish or review requests or chats prior to their presence on the
              website. Requests or chats do not reflect the views and opinions
              of Requesto, its agents and/or affiliates. Requests or chats
              reflect the views and opinions of the person who post their views
              and opinions. To the extent permitted by applicable laws, Requesto
              shall not be liable for the requests or chats or for any
              liability, damages or expenses caused and/or suffered as a result
              of any use of and/or posting of and/or appearance of the requests
              or chats on this website. Requesto reserves the right to monitor
              all Comments and to remove any requests or chats which can be
              considered inappropriate, offensive or causes breach of these
              Terms and Conditions.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              You warrant and represent that:
              <ul>
                <li>
                  You are entitled to post requests or chats on our website and
                  have all necessary licenses and consents to do so;
                </li>
                <li>
                  The requests or chats do not invade any intellectual property
                  right, including without limitation copyright, patent or
                  trademark of any third party;
                </li>
                <li>
                  The requests or chats do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy;
                </li>
                <li>
                  The requests or chats will not be used to solicit or promote
                  business or custom or present commercial activities or
                  unlawful activity.
                </li>
              </ul>
              You hereby grant Requesto a non-exclusive license to use,
              reproduce, edit and authorize others to use, reproduce and edit
              any of your requests or chats in any and all forms, formats or
              media.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>iFrames</strong>
            </Paragraph>
            <Paragraph>
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our Website.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>Data Protection and Privacy</strong>
            </Paragraph>
            <Paragraph>
              Requesto is committed to protecting your personal data in
              accordance with the upcoming Digital Personal Data Protection Bill
              (DPDPB). We only collect data necessary for the specific purpose
              we specify at the time of collection. You have the right to
              access, correct, erase, and transfer your data.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>Content Liability</strong>
            </Paragraph>
            <Paragraph>
              We shall not be held responsible for any content that appears on
              your Website. Also, we don't endorse user-generated content but
              can take steps to remove inappropriate content. You agree to
              protect and defend us against all claims that are rising on your
              Website. No link(s) should appear on any Website that may be
              interpreted as libelous, obscene or criminal, or which infringes,
              otherwise violates, or advocates the infringement or other
              violation of, any third party rights.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>Your Privacy</strong>
            </Paragraph>
            <Paragraph>Please read our Privacy Policy.</Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <Paragraph>
                <strong>Prohibited Activities and Content</strong>
              </Paragraph>
              <Paragraph>
                At Requesto, we are committed to ensuring that our platform is
                used solely for legal, ethical, and appropriate purposes. By
                using our website, you agree not to post any requests or engage
                in any activities that are illegal or violate applicable laws,
                including but not limited to:
                <ul>
                  <li>The reselling of unauthorized items.</li>
                  <li>
                    The sale or distribution of alcohol, drugs, or other
                    controlled substances.
                  </li>
                  <li>
                    The promotion of any illegal, harmful, or fraudulent
                    activities.
                  </li>
                </ul>
                Requesto reserves the right to monitor and review all requests
                and user activities on the platform. We do not endorse or
                support any illegal activities, and any content that is found to
                violate these terms will be promptly removed. If we discover any
                requests or actions that are unlawful, harmful, or against our
                terms, we will take immediate action, which may include removal
                of the content, suspension of user accounts, and reporting such
                activities to the appropriate authorities if necessary. We
                encourage all users to report any suspicious or inappropriate
                content, and we will review all such reports as quickly as
                possible to maintain a safe and lawful environment on our
                platform.
              </Paragraph>
              <Separator role="separator">
                <Dot />
                <Dot />
                <Dot />
              </Separator>
              <strong>Reservation of Rights</strong>
            </Paragraph>
            <Paragraph>
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amend these terms and conditions and its linking policy at any
              time. By continuously linking to our Website, you agree to be
              bound to and follow these linking terms and conditions.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>Removal of links from our website</strong>
            </Paragraph>
            <Paragraph>
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              <strong>Disclaimer</strong>
            </Paragraph>
            <Paragraph>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website. Nothing in this disclaimer will:
              <ul>
                <li>
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort and
              for breach of statutory duty.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </Paragraph>
          </Content>
          <Footer>
            <Author>Requesto Team</Author>
            {/* <Date>{new Date().getFullYear()}</Date> */}
          </Footer>
        </Container>
      </div>
    </>
  );
};

export default TC;
