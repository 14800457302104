import React, { useEffect, useState } from "react";
import "./MinimalistMobileMenu.css";
import AcademicRequestModal from "./RequestTemplates/AcademicRequestModal";
import CustomRequestModal from "./RequestTemplates/CustomRequestModal";
import DeliveryRequestModal from "./RequestTemplates/DeliveryRequestModal";
// Import the modal component
import MicroLearnRequestModal from "./RequestTemplates/MicrolearnRequestModal";
import SellRequestModal from "./RequestTemplates/SellRequestModal";
import { FaChalkboardTeacher } from "react-icons/fa";
// Import MicroLearn icon
import { FiBook, FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Import MicroLearnRequestModal

const MinimalistMobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentState, setCurrentState] = useState("main");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for Academic modal
  const [isMicroLearnModalOpen, setIsMicroLearnModalOpen] = useState(false); // State for MicroLearn modal
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false); // State for MicroLearn modal
  const [isCustomRequestModalOpen, setIsCustomRequestModalOpen] =
    useState(false); // State for MicroLearn modal
  const [isSellRequestModalOpen, setIsSellRequestModalOpen] = useState(false); // State for MicroLearn modal
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const flag = localStorage.getItem("flag");
    if (flag) {
      setIsDisabled(true);
    }
    if (isDisabled) {
      // Disable interactions if not ready
      document.body.style.pointerEvents = "none";
      document.getElementById("mmm-menuButton").style.pointerEvents = "auto";
    } else {
      // Enable interactions after button click
      document.body.style.pointerEvents = "auto";
    }

    return () => {
      // Cleanup to ensure pointer events are restored
      document.body.style.pointerEvents = "auto";
    };
  }, [isDisabled]);

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const menuItemsData = {
    main: [
      {
        icon: "eye",
        label: "View Request",
        onClick: () => navigate("/assignmentrequests"), // Navigate to the route
      },
      {
        icon: "plus-circle",
        label: "Sell Something",
        onClick: () => setIsSellRequestModalOpen(true), // Navigate to the route
      },
      {
        icon: "book",
        label: "Post Request",
        // onClick: () => setCurrentState("post"), // Switch state to "post"
        onClick: () => setIsCustomRequestModalOpen(true),
      },
    ],
    // post: [
    //   {
    //     icon: "plus-circle",
    //     label: "Custom Request",
    //     onClick: () => setIsCustomRequestModalOpen(true), // Navigate to the route
    //   },
    //   {
    //     icon: "plus-circle",
    //     label: "Delivery",
    //     onClick: () => setIsDeliveryModalOpen(true), // Navigate to the route
    //   },
    //   {
    //     // icon: "book-open",
    //     icon: "plus-circle",
    //     label: "Mentorship/Guidance",
    //     onClick: () => setIsMicroLearnModalOpen(true), // Open the MicroLearn modal
    //   },
    //   {
    //     icon: "plus-circle",
    //     label: "Post Assignment",
    //     onClick: () => setIsModalOpen(true), // Open the Academic modal
    //   },
    // ],
  };

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
    if (!isOpen) {
      setCurrentState("main");
      setIsDisabled(false);
      localStorage.removeItem("flag");
    }
  };

  const getIconPath = (icon) => {
    switch (icon) {
      case "file-text":
        return (
          <>
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </>
        );
      case "send":
        return (
          <>
            <line x1="22" y1="2" x2="11" y2="13"></line>
            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
          </>
        );
      case "book-open":
        return (
          <>
            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
          </>
        );
      case "plus-circle":
        return (
          <>
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </>
        );
      case "eye":
        return (
          <>
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M1 12s3-7 11-7 11 7 11 7-3 7-11 7-11-7-11-7"></path>
          </>
        );
      case "book":
        return (
          <>
            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
          </>
        );
      default:
        return null;
    }
  };

  const MicroLearnIcon = styled(FaChalkboardTeacher)`
    width: 30px;
    height: 30px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  `;

  return (
    <div className="mmm-container">
      <div className="mmm-menu-wrapper">
        {isDisabled && (
          <>
            <div className="mmm-overlay"></div>
            <div className="mmm-popup-message">Click here to get started!</div>
          </>
        )}

        <button
          id="mmm-menuButton"
          className="mmm-main-button"
          aria-label="Open menu"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
        {isOpen && (
          <div id="mmm-popup" className={`mmm-popup ${isOpen ? "open" : ""}`}>
            <div className="mmm-popup-content">
              <div className="mmm-popup-header">
                <h2 id="mmm-popupTitle" className="mmm-popup-title">
                  {currentState === "main" ? "Menu" : "Post Options"}
                </h2>

                <button
                  id="mmm-closeButton"
                  className="mmm-close-button"
                  aria-label="Close menu"
                  onClick={toggleMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div id="mmm-menuItems" className="mmm-menu-items">
                {menuItemsData[currentState].map((item, index) => (
                  <button
                    key={index}
                    className="mmm-menu-item"
                    style={{ animationDelay: `${index * 0.1}s` }}
                    onClick={item.onClick}
                  >
                    <div className="mmm-menu-item-content">
                      <span className="mmm-menu-item-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          {getIconPath(item.icon)}
                        </svg>
                      </span>
                      <span className="mmm-menu-item-label">{item.label}</span>
                    </div>
                    <span className="mmm-menu-item-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        <AcademicRequestModal
          showModal={isModalOpen}
          closeModal={handleCloseModal}
        />

        <MicroLearnRequestModal
          showModal={isMicroLearnModalOpen}
          closeModal={() => setIsMicroLearnModalOpen(false)}
        />

        <DeliveryRequestModal
          showModal={isDeliveryModalOpen}
          closeModal={() => setIsDeliveryModalOpen(false)}
        />
        <CustomRequestModal
          showModal={isCustomRequestModalOpen}
          closeModal={() => setIsCustomRequestModalOpen(false)}
        />
        <SellRequestModal
          showModal={isSellRequestModalOpen}
          closeModal={() => setIsSellRequestModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default MinimalistMobileMenu;
