import React from "react";
import "./Test1.css";
import Navbar1 from "../Navbar";

// Import the CSS file

const Test1 = () => {
  return (
    <div className="req-container">
      <Navbar1 />
      <main className="req-main">
        <section className="req-intro-section">
          <div className="req-intro-content">
            <h2 className="req-title">Connect, Request, Achieve</h2>
            <p className="req-description">
              The platform where students post and accept requests, bringing
              campus communities together.
            </p>
            <button className="req-btn req-btn-lg">Get Started</button>
          </div>
        </section>

        <section className="req-cards-section">
          <h3 className="req-section-title">Why Requesto?</h3>
          <div className="req-cards-grid">
            <div className="req-card">
              <div className="req-card-content">
                <i className="req-icon">📝</i>
                <h4 className="req-card-title">Post Requests</h4>
                <p className="req-card-description">
                  Easily post your needs and get help from peers.
                </p>
              </div>
            </div>
            <div className="req-card">
              <div className="req-card-content">
                <i className="req-icon">✔️</i>
                <h4 className="req-card-title">Accept Tasks</h4>
                <p className="req-card-description">
                  Find opportunities to help and earn.
                </p>
              </div>
            </div>
            <div className="req-card">
              <div className="req-card-content">
                <i className="req-icon">📍</i>
                <h4 className="req-card-title">Location-based</h4>
                <p className="req-card-description">
                  Connect with people nearby on campus.
                </p>
              </div>
            </div>
            <div className="req-card">
              <div className="req-card-content">
                <i className="req-icon">👥</i>
                <h4 className="req-card-title">Community</h4>
                <p className="req-card-description">
                  Build a stronger campus community.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="req-college-section">
          <h3 className="req-section-title">Choose Your College</h3>
          <div className="req-college-grid">
            {["DTU", "NSUT", "IIITD", "IGDTUW"].map((college) => (
              <a
                key={college}
                href={`https://${college.toLowerCase()}.requesto.in`}
                className="req-college-btn"
              >
                {college}
              </a>
            ))}
          </div>
        </section>
      </main>

      <footer className="req-footer">
        <p className="req-footer-text">
          &copy; 2024 Requesto. All rights reserved.
        </p>
        <div className="req-footer-links">
          <a href="/about" className="req-footer-link">
            About
          </a>
          <a href="/privacy" className="req-footer-link">
            Privacy Policy
          </a>
          <a href="/terms" className="req-footer-link">
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Test1;
