import React, { useState } from "react";
import "./DeliveryRequestModal.css";

// Import the CSS file for styling

const DeliveryRequestModal = ({ showModal, closeModal }) => {
  const [packageDescription, setPackageDescription] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const deadline = `${days} days ${hours} hours ${minutes} minutes`;
      const sender = localStorage.getItem("name"); // Assuming the sender's name is stored in localStorage
      const sender_email = localStorage.getItem("email");

      // Reusing the academic request API as a stopgap
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assignment: packageDescription, // Use assignment field for package description
            price: deliveryFee, // Use price field for delivery fee
            subject: deliveryLocation, // Use subject field for delivery location
            deadline, // Deadline remains the same
            details: additionalNotes, // Use details field for additional notes
            sender,
            sender_email,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        // alert("Delivery request submitted successfully");
        // Reset the form fields
        setPackageDescription("");
        setDeliveryFee("");
        setPickupLocation("");
        setDeliveryLocation("");
        setDays("");
        setHours("");
        setMinutes("");
        setAdditionalNotes("");
        closeModal();
        setTimeout(() => {
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300);
      } else {
        alert(result.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred");
    }
  };

  return (
    <>
      <div className={`modal-overlay ${showModal ? "show" : ""}`}>
        <div className="modal-content">
          <h3 className="acr-2">Post Delivery Request</h3>
          <span className="close-button-acr" onClick={closeModal}>
            &times;
          </span>
          <form onSubmit={handleSubmit} className="acr-6">
            <label className="acr-10">
              Package Description:
              <input
                type="text"
                value={packageDescription}
                onChange={(e) => setPackageDescription(e.target.value)}
                required
                className="acr-11"
              />
            </label>
            <label className="acr-10">
              Delivery Location:
              <input
                type="text"
                value={deliveryLocation}
                onChange={(e) => setDeliveryLocation(e.target.value)}
                required
                className="acr-11"
              />
            </label>
            <label className="acr-10">
              Delivery Fee (₹):
              <input
                type="number"
                value={deliveryFee}
                onChange={(e) => setDeliveryFee(e.target.value)}
                required
                className="acr-11"
              />
            </label>
            <label className="acr-10">
              Delivery Time:
              <div className="deadline-container">
                <input
                  type="number"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Days"
                />
                <input
                  type="number"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Hours"
                />
                <input
                  type="number"
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Minutes"
                />
              </div>
            </label>
            <label className="acr-10">
              Additional Notes (Optional):
              <textarea
                value={additionalNotes}
                onChange={(e) => setAdditionalNotes(e.target.value)}
                className="acr-14"
              />
            </label>
            <button type="submit" className="acr-22">
              Submit Delivery Request
            </button>
          </form>
        </div>
      </div>
      {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Request sent successfully!
              </div>
            </div>
          </li>
        </ul>
      )}
    </>
  );
};

export default DeliveryRequestModal;
