import {
  GoogleMap,
  useLoadScript,
  Circle,
  Marker,
} from "@react-google-maps/api";
import React, { useState, useEffect } from "react";
import "./MapTest.css";
import { useLocation } from "react-router-dom";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "500px",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const MapTest = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [currentLocation, setCurrentLocation] = useState(null);
  const [radius, setRadius] = useState(0); // default radius in meters
  const [duration, setDuration] = useState(15); // default duration in minutes
  const [confirmed, setConfirmed] = useState(false); // for confirming the input
  const [timer, setTimer] = useState(null); // for countdown timer
  const [message, setMessage] = useState(""); // For API feedback
  const [nearbyUsers, setNearbyUsers] = useState([]); // State for nearby users
  const [userLocations, setUserLocations] = useState([]);
  const { state } = useLocation(); // Get state from location
  const [requestDetails, setRequestDetails] = useState(null);
  const [showFinalDetails, setShowFinalDetails] = useState(false);

  // Function to handle "Final Details" button click
  const handleFinalDetails = () => {
    const details = `
      Request Details:
      Request Name: ${requestDetails?.requestName || "N/A"}
      Description: ${requestDetails?.description || "N/A"}
      Category: ${requestDetails?.category || "N/A"}
      Location: Latitude: ${currentLocation.lat}, Longitude: ${
      currentLocation.lng
    }
      Radius: ${radius / 1000} km
      Duration: ${duration} minutes
    `;
    alert(details);
  };

  useEffect(() => {
    // Show the Final Details button only when all required info is available
    if (currentLocation && radius > 0 && duration > 0 && confirmed) {
      setShowFinalDetails(true);
    } else {
      setShowFinalDetails(false);
    }
  }, [radius, duration, confirmed]);

  const handleRadiusChange = (e) => {
    setRadius(Number(e.target.value) * 1000); // Convert km to meters
  };

  const handleDurationChange = (e) => {
    setDuration(Number(e.target.value));
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          alert("Failed to retrieve location");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const confirmSelection = () => {
    setConfirmed(true);
  };

  // const createRequest = async () => {
  //   if (!currentLocation || !radius || !duration) {
  //     alert("Please confirm location, radius, and duration first.");
  //     return;
  //   }

  //   try {
  //     const response = await fetch("http://localhost:5000/api/map/create", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         userId: "64b0f4e8e4b0c71a5d9b3c56", // Replace with actual user ID from your database
  //         location: {
  //           lat: currentLocation.lat,
  //           lng: currentLocation.lng,
  //         },
  //         radius,
  //         duration,
  //       }),
  //     });

  //     const data = await response.json();
  //     if (response.ok) {
  //       setMessage(data.message || "Request created successfully!");
  //     } else {
  //       setMessage(data.error || "Failed to create request");
  //     }
  //   } catch (error) {
  //     setMessage("Error creating request: " + error.message);
  //   }
  // };

  const createRequest = async () => {
    if (!currentLocation || !radius || !duration) {
      alert("Please confirm location, radius, and duration first.");
      return;
    }

    try {
      const response = await fetch("http://localhost:5000/api/map/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: "64b0f4e8e4b0c71a5d9b3c56", // Replace with actual user ID from your database
          location: {
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          },
          radius,
          duration,
          requestName: requestDetails.requestName,
          description: requestDetails.description,
          category: requestDetails.category,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message || "Request created successfully!");
      } else {
        setMessage(data.error || "Failed to create request");
      }
    } catch (error) {
      setMessage("Error creating request: " + error.message);
    }
  };

  const fetchNearbyUsers = () => {
    if (!currentLocation) return;

    const usersWithinRadius = userLocations.filter((user) => {
      if (!user.location) return false; // Safety check in case location is missing

      const distance = getDistanceFromLatLonInKm(
        currentLocation.lat,
        currentLocation.lng,
        user.location.lat, // Access location lat
        user.location.lng // Access location lng
      );
      return distance <= radius / 1000; // Convert radius from meters to km
    });

    setNearbyUsers(usersWithinRadius);
  };

  // Utility function to calculate distance between two points
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    const fetchUserLocations = async () => {
      try {
        const response = await fetch("http://localhost:5000/api/map/locations");
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();

        console.log("Fetched data:", data);
        setUserLocations(data); // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching user locations:", error);
      }
    };

    fetchUserLocations();
  }, []);

  // Log when `userLocations` updates
  useEffect(() => {
    console.log("Updated userLocations:", userLocations);
  }, [userLocations]);

  useEffect(() => {
    fetchNearbyUsers(); // Update nearby users when radius or location changes
  }, [radius]);

  useEffect(() => {
    if (state) {
      setRequestDetails(state); // Set request details from state
    }
  }, [state]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div className="map-container">
      <br />
      <button onClick={getLocation} className="map-btn create-btn">
        Request in Vicinity
      </button>

      {currentLocation && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={currentLocation}
          options={options}
        >
          <Marker position={currentLocation} />
          <Circle
            center={currentLocation}
            radius={radius}
            options={{
              fillColor: "#FF0000",
              fillOpacity: 0.2,
              strokeColor: "#FF0000",
              strokeOpacity: 0.5,
              strokeWeight: 2,
            }}
          />

          {nearbyUsers.map((user, index) => (
            <Marker
              key={index}
              position={{ lat: user.location.lat, lng: user.location.lng }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              }}
              label={user.username}
            />
          ))}
        </GoogleMap>
      )}
      <br />
      <h1>Set your Preferences</h1>
      <div className="map-controls-container">
        <div className="map-controls-tr-container">
          <label className="map-label">
            Radius :
            <select
              className="map-select"
              onChange={handleRadiusChange}
              value={radius / 1000}
            >
              <option value={0.2}>200 mts</option>
              <option value={0.5}>500 mts</option>
              <option value={0.8}>800 mts</option>
              <option value={1}>1 km</option>
              <option value={2}>2 km</option>
              <option value={3}>3 km</option>
            </select>
          </label>

          <label className="map-label">
            Duration:
            <select
              className="map-select"
              onChange={handleDurationChange}
              value={duration}
            >
              <option value={15}>15 min</option>
              <option value={30}>30 min</option>
              <option value={45}>45 min</option>
              <option value={60}>60 min</option>
            </select>
          </label>
        </div>
        <br />

        <div>
          <label className="map-label">
            <p className="map-user-count">
              {nearbyUsers.length} nearby users found
            </p>
          </label>
        </div>
        {currentLocation && (
          <>
            <button onClick={createRequest} className="map-btn create-btn">
              Create Request
            </button>
          </>
        )}
        {currentLocation && (
          <>
            <button onClick={confirmSelection} className="map-btn create-btn">
              Confirm
            </button>
          </>
        )}
        {showFinalDetails && (
          <div>
            <button onClick={handleFinalDetails} className="map-btn create-btn">
              Show Final Details
            </button>
          </div>
        )}
      </div>
      <br />
      <br />
      <br />

      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default MapTest;
