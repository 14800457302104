import { MessageSquare, UserPlus, CheckCircle } from "lucide-react";
import React, { useState } from "react";
import "./WelcomePopups.css";

const WelcomePopups = () => {
  const [currentPopup, setCurrentPopup] = useState(0);
  const [showPopups, setShowPopups] = useState(true);

  const popups = [
    {
      title: "Welcome to Requesto!",
      content:
        "Requesto is a platform where you can post and accept requests from others in your college.",
      icon: <MessageSquare className="wp-icon" />,
    },
    {
      title: "Post Your Requests",
      content:
        "Need help with something? Create a request and let others in your college assist you.",
      icon: <UserPlus className="wp-icon" />,
    },
    {
      title: "Accept Requests",
      content:
        "Browse through requests from others and offer your help. Build connections and Earn money.",
      icon: <CheckCircle className="wp-icon" />,
    },
  ];

  const handleNext = () => {
    if (currentPopup < popups.length - 1) {
      setCurrentPopup(currentPopup + 1);
    } else {
      setShowPopups(false);
    }
  };

  const handleSkip = () => {
    setShowPopups(false);
  };

  if (!showPopups) return null;

  return (
    <div className={`wp-dialog ${showPopups ? "open" : ""}`}>
      <div className="wp-dialog-content">
        <div className="wp-card">
          <div className="wp-card-header">
            <h2 className="wp-card-title">{popups[currentPopup].title}</h2>
          </div>
          <div className="wp-card-content">
            <div className="wp-icon-container">{popups[currentPopup].icon}</div>
            <p className="wp-content-text">{popups[currentPopup].content}</p>
          </div>
          <div className="wp-card-footer">
            <button className="wp-button outline" onClick={handleSkip}>
              Skip
            </button>
            <button className="wp-button primary" onClick={handleNext}>
              {currentPopup < popups.length - 1 ? "Next" : "Get Started"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePopups;
