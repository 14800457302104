import React, { useState } from "react";
import "./Test3.css";

// Load Google Maps JavaScript API
function loadGoogleMapsAPI(callback) {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY`;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
}

const Test3 = () => {
  const [radius, setRadius] = useState("200 mts");
  const [waitTime, setWaitTime] = useState("15 mins");
  const [userCount, setUserCount] = useState(0);

  // Initialize map after component mounts
  React.useEffect(() => {
    loadGoogleMapsAPI(() => {
      const map = new window.google.maps.Map(
        document.getElementById("mapContainer-gmap"),
        {
          center: { lat: 17.385, lng: 78.4867 }, // Hyderabad coordinates as an example
          zoom: 16,
        }
      );
      new window.google.maps.Marker({
        position: { lat: 17.385, lng: 78.4867 },
        map: map,
      });
    });
  }, []);

  return (
    <div className="poolingPage-gmap">
      <div className="mapContainer-gmap" id="mapContainer-gmap" />

      <div className="preferencesContainer-gmap">
        <h2 className="preferencesTitle-gmap">Set your Preferences</h2>

        <div className="preferenceOptions-gmap">
          <label className="preferenceLabel-gmap">
            Radius
            <select
              className="preferenceSelect-gmap"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
            >
              <option value="200 mts">200 mts</option>
              <option value="500 mts">500 mts</option>
              <option value="1 km">1 km</option>
            </select>
          </label>

          <label className="preferenceLabel-gmap">
            Wait Time
            <select
              className="preferenceSelect-gmap"
              value={waitTime}
              onChange={(e) => setWaitTime(e.target.value)}
            >
              <option value="15 mins">15 mins</option>
              <option value="30 mins">30 mins</option>
              <option value="1 hour">1 hour</option>
            </select>
          </label>
        </div>

        <p className="userCount-gmap">Pooling with {userCount} users</p>
        <button className="startPoolingButton-gmap">Start Pooling</button>
      </div>
    </div>
  );
};

export default Test3;
