import AnimatedButtons from "../Buttons/Animations";
import Chatbutton from "../Buttons/Chatbutton";
import MinimalistMobileMenu from "../MinimalistMobileMenu";
import Navbar1 from "../Navbar";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  CardFooter,
} from "../styles";
import { blogarray } from "./blogarray";
import blog4img from "./images/blog4.webp";
import blog2img from "./images/blog21.webp";
import blog3img from "./images/blog31.jpg";
import dtufoto from "./images/dtu.webp";
import people from "./images/people.webp";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

const Blog4 = () => {
  const navigate = useNavigate();
  const topRef = useRef(null);
  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`); // Example URL structure, adjust as per your routing setup
  };
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div ref={topRef} style={{ height: "0", margin: "0", padding: "0" }}>
        <GlobalStyle />
        <Navbar1 />
        <Container>
          <Header>
            <HeaderImage src={blog4img} alt="Header Image" />
            <Title>
              Discovering Society Culture at DTU: A Guide for Freshers
            </Title>
            <Author>by Requesto</Author>
            <Date>July 18, 2024</Date>
          </Header>
          <Separator role="separator">
            <Dot />
            <Dot />
            <Dot />
          </Separator>

          <Content>
            <Paragraph>
              Welcome to Delhi Technological University (DTU), a prestigious
              tier-1 engineering institution that competes with the IITs in
              various fields. Spanning 164 acres, DTU boasts numerous facilities
              and a vibrant student community. As a curious student who has
              worked hard for the JEE, you must have high expectations and
              excitement about what lies ahead. Now that you’ve achieved a
              commendable AIR, it’s time to pursue your true passions, which may
              have been set aside during your PCM grind in 11th and 12th grade.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              At DTU, you’ll find a wide range of societies catering to diverse
              interests. Whether you’re passionate about dance, music,
              technology, or startups, DTU has a society for you. Here’s a
              structured overview of some prominent societies you can join to
              make the most of your college experience:
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              1. Performing Arts Societies VIBE: If you’re passionate about
              dance, VIBE is the society for you. It boasts talented dancers who
              have honed their skills over years of practice and participate in
              numerous competitions.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              MADHURIMA: For music enthusiasts, MADHURIMA offers a platform for
              guitarists, drummers, and musicians of all kinds. It’s a great
              place to nurture your musical talent and perform with like-minded
              individuals.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              PRATIBIMB: This is the society for aspiring actors. PRATIBIMB is
              highly competitive and involves dedicated individuals who spend
              their days acting, performing skits, and filmmaking.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              . Technical Societies INFERNO, DEFIANZ, and BAHA: These tech
              societies work tirelessly on projects involving drones, F1 racing
              cars, and buggies. They represent DTU in national competitions and
              provide an excellent platform for tech enthusiasts. 3. Art and
              Literature Societies KALAKRITI: If you’re an artist, KALAKRITI
              allows you to express your creativity through various forms of
              art. It’s a haven for those who love to pen down their thoughts
              and ideas.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Sahitya and Cognitive Minds: These societies focus on debating and
              have been participating in inter-IIT competitions for years. They
              offer a space for intellectual discussions and literary pursuits.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Management and Entrepreneurial Societies Cultural Council (CC):
              One of the most prestigious societies, CC manages major events and
              fests at DTU, including ENGIFEST, one of India’s largest college
              fests. Last year, ENGIFEST attracted over 200,000 people with
              performances by Shreya Ghoshal, Raga, Talwinder, and other big
              stars. E-Cell: This society focuses on entrepreneurship and
              startups. It’s involved in contacting sponsors and conducting
              events like E-Summit, providing a great platform for budding
              entrepreneurs. STEP DTU: Known for conducting numerous events,
              STEP DTU offers opportunities for networking with like-minded
              individuals and developing managerial skills.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              If you’re unsure of your interests, join different societies to
              discover what you truly enjoy and want to pursue. DTU is a land of
              opportunities, but remember, these societies will demand a
              considerable amount of your time. However, staying consistent and
              actively participating can lead to immense personal growth and new
              skills.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Embrace the society culture at DTU, and make the most of your
              college journey. Good luck, and welcome to DTU!
            </Paragraph>
          </Content>

          <br />
          <br />
          <br />
          <br />
          <ButtonContainer>
            <NavButton onClick={() => navigate("/blog3")}>Previous</NavButton>
            <NavButton onClick={() => navigate("/blog7")}>Next</NavButton>
          </ButtonContainer>
          {/* {blogarray.map((blog) => (
            <BlogCard key={blog.id}>
              <CardImage src={blog.image} alt="Blog Preview" />
              <CardContent>
                <CardTitle>{blog.title}</CardTitle>
                <CardAuthor>by {blog.author}</CardAuthor>
                <CardDate>{blog.date}</CardDate>
                <CardDescription>{blog.description}</CardDescription>
              </CardContent>
              <CardFooter>
                <ReadMoreButton onClick={() => handleReadMore(blog.id)}>
                  Read More
                </ReadMoreButton>
              </CardFooter>
            </BlogCard>
          ))} */}
        </Container>
        <MinimalistMobileMenu />
        <Footer>&copy; 2024 Requesto. All rights reserved.</Footer>
      </div>
    </>
  );
};

export default Blog4;
