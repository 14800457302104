import MinimalistMobileMenu from "../MinimalistMobileMenu";
import Navbar1 from "../Navbar";
// import {
//   ReadMoreButton,
// Footer,
// BlogCard,
// CardImage,
// CardContent,
// CardTitle,
// CardAuthor,
// CardDate,
// } from "./styles";

import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  ScrollContainer,
  CardFooter,
} from "../styles";
import { blogarray } from "./blogarray";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     font-family: Arial, Helvetica, sans-serif;
//     overflow-x: hidden;
//   }

//   h2 {
//     text-align: center;
//     margin-top: 20px;
//   }
// `;

// const ScrollContainer = styled.div`
//   display: flex;
//   overflow-x: scroll;
//   scroll-snap-type: x mandatory;
//   padding: 20px;
//   gap: 20px;
//   align-items: center;
//   justify-content: flex-start;
//   scroll-behavior: smooth;
//   width: 100%;
//   max-width: 100vw;

//   &::-webkit-scrollbar {
//     display: none;
//   }

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const BlogCard = styled.div`
//   flex: 0 0 280px;
//   height: 400px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   background-color: #ffffff;
//   border-radius: 12px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
//   transition: transform 0.3s ease;
//   transform-origin: center;
//   scroll-snap-align: center;
//   scroll-snap-stop: always;
//   width: 280px;
//   cursor: pointer;

//   &:hover {
//     transform: scale(1.05);
//   }

//   @media (max-width: 768px) {
//     flex: 0 0 220px;
//     height: 350px;
//   }
// `;

// const CardImage = styled.img`
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 12px 12px 0 0;

//   @media (max-width: 768px) {
//     height: 150px;
//   }
// `;

// const CardContent = styled.div`
//   padding: 15px;
//   text-align: center;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const CardTitle = styled.h2`
//   font-size: 1.3em;
//   margin-bottom: 10px;

//   @media (max-width: 768px) {
//     font-size: 1.1em;
//   }
// `;

// const CardAuthor = styled.div`
//   font-size: 1em;
//   color: #666;
// `;

// const CardDate = styled.div`
//   font-size: 0.9em;
//   color: #888;
// `;

// const Footer = styled.footer`
//   text-align: center;
//   padding: 20px;
//   font-size: 0.9em;
//   color: #aaa;
//   border-top: 1px solid #ddd;
//   margin-top: 40px;

//   @media (max-width: 768px) {
//     font-size: 0.8em;
//     padding: 15px;
//   }
// `;

const Test_B = () => {
  const scrollRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      const blogs = scrollRef.current.querySelectorAll(".blog");
      const viewportWidth = scrollRef.current.offsetWidth;
      const screenCenter = viewportWidth / 2;

      blogs.forEach((blog, index) => {
        const blogRect = blog.getBoundingClientRect();
        const blogCenter = (blogRect.left + blogRect.right) / 2;
        const distanceFromCenter = Math.abs(blogCenter - screenCenter);
        const maxDistance = screenCenter;

        const scale = Math.max(0.85, 1 - distanceFromCenter / maxDistance);
        blog.style.transform = `scale(${scale})`;

        if (distanceFromCenter < blogRect.width / 2) {
          setActiveIndex(index);
        }
      });
    };

    const scrollContainer = scrollRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set scale

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <Navbar1 />
      <h2>Our Blogs</h2>
      <ScrollContainer ref={scrollRef}>
        {blogarray.map((blog, index) => (
          <BlogCard
            key={blog.id}
            className="blog"
            style={{ transform: `scale(${activeIndex === index ? 1 : 0.85})` }}
            onClick={() => handleReadMore(blog.id)}
          >
            <CardImage src={blog.image} alt={blog.title} />
            <CardContent>
              <CardTitle>{blog.title}</CardTitle>
              <CardAuthor>by {blog.author}</CardAuthor>
              <CardDate>{blog.date}</CardDate>
              <ReadMoreButton>Read More</ReadMoreButton>
            </CardContent>
          </BlogCard>
        ))}
      </ScrollContainer>
      <MinimalistMobileMenu />
      <Footer>&copy; 2024 Requesto. All rights reserved.</Footer>
    </>
  );
};

export default Test_B;
